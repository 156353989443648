
import { DeleteOutlined, PictureOutlined, SendOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Tooltip, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { useDisconnect } from 'wagmi';
import { AdminApi } from '../../config/api/AdminApi';
import { notiError, notiSuccess } from '../../utils/notication';

const ContactForm = (prop: any) => {
    const { address, addContact, parentId, isBlacklisted } = prop;
    const [contact, setContact] = useState({ name: '', address: '', content: '', email: '', imageUrl: '', parentId: null });
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isValidFile, setIsValidFile] = useState(false);
    let jwtToken: any = localStorage.getItem('jwt');
    const { disconnect } = useDisconnect();
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setContact((prevContact) => ({ ...prevContact, [name]: value }));
    };

    const submitContactForm = async () => {
        if (!contact.content) {
            return;
        }
        contact.address = address;
        if (parentId > 0) {
            contact.parentId = parentId;
        }

        if (!jwtToken) {
            notiError("Your message failed to send. Only staked users can send contact messages.", 5);
            return;
        }
        await AdminApi.sendContact(contact)
            .then((res: any) => {
                if (res && res.status === 200 && res.data) {
                    addContact(res.data);
                    setContact({ name: '', address: '', content: '', email: '', imageUrl: '', parentId: null });
                    notiSuccess('Your message sent successfully.', 5);
                    setIsOpenModal(false);
                }
                else {
                    notiError(`Failed to send your message. Please try again later.`, 5);
                }

            }).catch((error) => {
                if (error.response && error.response.status === 401 && jwtToken) {
                    notiError('Your session has expired. Please log in again.', 5);
                    disconnect();
                    localStorage.removeItem('jwt');
                } else {
                    notiError('Failed to send your message. Please try again later.', 5);
                }
            });
    };

    const uploadContactImage = () => {
        setIsOpenModal(true);
    };

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const [image, setImage] = useState<File | null>(null);
    const [comment, setComment] = useState<string>('');

    const onUpload = ({ file, fileList }: any) => {

        const fileSizeInMB = file.size / 1024 / 1024;
        if (fileSizeInMB > 100) {
            notiError('File size exceeds 100MB', 5);
            return;
        }
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/zip', 'application/x-zip-compressed', 'application/x-rar-compressed'];
        if (!allowedTypes.includes(file.type)) {
            notiError('Invalid file type. Please upload an image or zip/rar file.', 5);
            return;
        }
        setIsValidFile(true);
        setFileList(fileList);
    };

    const uploadLoader = async ({ file, onSuccess, onError }: any) => {
        setIsUploading(true);
        try {
            if (isValidFile) {
                handleSubmitUpload(file);
                onSuccess("ok");
                if (contact.imageUrl) {
                    setContact((prevContact) => ({ ...prevContact, imageUrl: contact.imageUrl }));
                    setImage(file);
                }
                notiSuccess('Image uploaded successfully', 5);
            }
            else {
                return;
            }

        } catch (error) {
            onError(error);
            notiError('Upload failed', 5);
        } finally {
            setIsUploading(false);
        }
    };

    const removeImage = async (imageUrl: string) => {
        const response = await AdminApi.removeImage(imageUrl);
        if (response.status === 200) {
            setContact((prevContact) => ({ ...prevContact, imageUrl: '' }));
        }

        notiSuccess('Image removed successfully', 5);
    }
    function onClickClose(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
        setIsOpenModal(false);
    }

    const handleSubmitUpload = async (file: any) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            console.log(file.size);
            const response = await AdminApi.uploadImage(formData);
            if (response.status === 401) {
                notiError('Your session has expired. Please log in again.', 5);
                disconnect();
                localStorage.removeItem('jwt');
                return;
            }
            if (response.status !== 200) {
                notiError('Image upload failed. Please try again later.', 5);
                return;
            }
            const imageUrl = response?.data.imageUrl;
            setContact((prevContact) => ({ ...prevContact, imageUrl }));
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };
    const serverImage = (imageUrl: string, domain: string = ""): string | undefined => {
        if (!imageUrl) return undefined;
        if (imageUrl.endsWith('.zip')) return "../images/zip.png";
        if (imageUrl.endsWith('.rar')) return "../images/rar.png";

        const baseUrl = domain.includes("http") ? domain : '';
        return `${baseUrl}${imageUrl}`;
    };

    return (
        <>
            {!isBlacklisted && jwtToken && (
                <div className="contact-form-container mt-15">
                    <div className="contact-panel">
                        <Input.TextArea
                            placeholder="Content"
                            name="content"
                            value={contact.content}
                            onChange={handleChange}
                            required
                            autoSize={{ minRows: 3, maxRows: 6 }}
                            style={{ backgroundColor: 'transparent', border: '1px solid rgba(202, 236, 241, 0.07)' }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    submitContactForm();
                                }
                            }}
                        />
                        <div className="suffix-container">
                            <Tooltip title={address ? "Send" : "Connect wallet to send"}>
                                <button className='iconButton primary-icon mr-5' onClick={submitContactForm}
                                    disabled={!contact.content || !address} >
                                    <SendOutlined />
                                </button>
                            </Tooltip>
                            <Tooltip title={address ? "Upload image" : "Connnect wallet to upload"}>
                                <button className='iconButton primary-icon' onClick={uploadContactImage}
                                    disabled={!address}>
                                    <PictureOutlined name="contactImage" />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            )}

            <Modal footer={false} className="modal-cus contact-modal" title={<><span className="text-white weight-500 font-20">Upload image</span></>} open={isOpenModal} onOk={() => window.location.reload} onCancel={() => { setIsOpenModal(false) }}>
                <p className="border-bottom-green"></p>
                <div className="contact-info-dialog-container ">
                    <form className="p-x-15">
                        <div className="mat-dialog-content">
                            <div className="image-section mb-15">
                                <div className="section-label mb-10 text-white">Image</div>
                                {contact.imageUrl && contact.imageUrl !== "" && (
                                    <div className="section-image">
                                        <img src={serverImage(contact.imageUrl, process.env.REACT_APP_ADMIN_API_URL)} width="100px" height="100px" />
                                        <DeleteOutlined className="pointer remove-image" onClick={() => removeImage(contact.imageUrl)} title="Remove image" />
                                    </div>
                                )}
                                <Upload.Dragger
                                    className={`square upload-box ${isUploading ? 'uploading' : ''}`}
                                    customRequest={uploadLoader}
                                    onChange={onUpload}
                                    accept="image/*,.zip,.rar"
                                    name="upload"
                                    showUploadList={false}
                                    fileList={fileList}
                                    disabled={isBlacklisted || isClosed}
                                    maxCount={1}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                    </p>
                                    <p className="text-white">
                                        Click or drag file to this area to upload <br />
                                        Maximum upload size allowed is 100MB <br />
                                        Supported file types: jpg, jpeg, png, gif, zip, rar
                                    </p>
                                </Upload.Dragger>
                            </div>
                            <div className="section-label mb-10 text-white">Content</div>
                            <Input.TextArea
                                value={contact.content}
                                onChange={handleChange}
                                placeholder="Reply content"
                                name="content"
                                autoSize={{ minRows: 3, maxRows: 6 }}
                                required
                                style={{ backgroundColor: 'transparent', border: '1px solid rgba(202, 236, 241, 0.5)', color: '#fff' }}
                            />
                        </div>

                        <div className="mat-dialog-actions mt-15">
                            <Button className='ant-btn ant-btn-default ant-btn-lg btn-dark full-width' onClick={submitContactForm} disabled={isBlacklisted || !contact.content}>
                                Send
                            </Button>
                            <Button type="default" size="large" className="btn-green w-full mt-15" onClick={onClickClose}>
                                Cancel
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default ContactForm;