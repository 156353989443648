
import { Col, message, Tabs, Layout, Row, Select, Empty, Tooltip, Table, Pagination } from "antd";
import { Content, Header } from "antd/es/layout/layout";

import React, { useState, useRef, useEffect } from "react";

import { CaretDownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import type { TabsProps } from 'antd';
import HeaderApp from "../header-app";
import { BaseApi } from "../../config/api/BaseApi";
import CountdownComponent from "../../utils/countDown";
import Footer from "../../component/common/Footer";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import Web3 from "web3";
import { Line } from "@ant-design/charts";
import { convertDateTime, formatUTCDate } from "../../utils/formatDate";

const { Option } = Select;
declare const window: Window &
  typeof globalThis & {
    trustwallet: any;
    ethereum: any;
    bitkeep: any;
    okexchain: any;
    safepalProvider: any;
  };

const StakingList = () => {

  const { address } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState()
  const chainId: any = selectedNetworkId
  const [configApy, setConfigApy]: any = useState();
  const [pools, setPools]: any = useState([]);
  const [activeStatus, setActiveStatus]: any = useState('');
  const [totalRow, setTotalRow] = useState(0);
  const [stakingPlatformsData, setStakingPlatformsData]: any = useState([]);
  const [stakingStablehodlData, setStakingStablehodlData]: any = useState([]);
  const [tradePlatformData, setTradePlatformData]: any = useState([]);

  let web3: any;
  const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");


  if (connectorId === "io.metamask" || connectorId === "bsc") {
    web3 = new Web3(window.ethereum);
  } else if (connectorId === "com.trustwallet.app") {
    web3 = new Web3(window.trustwallet);
  } else if (connectorId === "com.bitget.web3") {
    web3 = new Web3(window.bitkeep.ethereum);
  } else if (connectorId === "com.okex.wallet") {
    web3 = new Web3(window.okexchain);
  } else {
    web3 = new Web3(window.ethereum);
  }

  const [param, setParam]: any = useState({
    page: 1,
    size: 100
  });

  function filterArray(arr: any, activeStatus: any) {
    if (activeStatus == 'upcoming') {
      var numArr = [];
      for (let i = 0; i < arr.length; i++) {
        let item: any = arr[i];
        let nowDate = new Date(Date.now());
        let startTime = item?.start_time ? new Date(item?.start_time) : '';
        let endTime = item?.end_time ? new Date(item?.end_time) : '';

        if (startTime > nowDate) {
          numArr.push(arr[i]);
        }
      }
      return numArr;
    } else if (activeStatus == 'live') {
      var numArr = [];
      for (let i = 0; i < arr.length; i++) {
        let item: any = arr[i];
        let nowDate = new Date(Date.now());
        let startTime = item?.start_time ? new Date(item?.start_time) : '';
        let endTime = item?.end_time ? new Date(item?.end_time) : '';

        if (startTime != '' && startTime < nowDate && endTime > nowDate) {
          numArr.push(arr[i]);
        }
      }
      return numArr;
    } else if (activeStatus == 'finished') {
      var numArr = [];
      for (let i = 0; i < arr.length; i++) {
        let item: any = arr[i];
        let nowDate = new Date(Date.now());
        let startTime = item?.start_time ? new Date(item?.start_time) : '';
        let endTime = item?.end_time ? new Date(item?.end_time) : '';

        if (startTime != '' && startTime < nowDate && endTime < nowDate) {
          numArr.push(arr[i]);
        }
      }
      return numArr;
    } else {
      return arr;
    }


  }

  const getListPools = async () => {
    await BaseApi.getPoolList(param).then((res) => {

      if (res.status == 200 && res.data && res.data.data) {
        let poolsData: any = res.data.data;

        let data: any = filterArray(poolsData, activeStatus);

        setPools(data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  const getConfigApy = async () => {
    await BaseApi.getConfigApy().then((res) => {
      if (res.status == 200 && res.data.data) {
        setConfigApy(res.data.data);
      }
    }).catch();
  }
  const getStakingPlatformsCharts = async () => {
    await BaseApi.getStakingPlatformsCharts().then((res) => {

      if (res.status == 200 && res.data.data) {
        setStakingPlatformsData(res.data.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }
  const getStakingStablehodlCharts = async () => {
    await BaseApi.getStakingStablehodlCharts().then((res) => {

      if (res.status == 200 && res.data.data) {
        setStakingStablehodlData(res.data.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }
  const getTradePlatformData = async () => {
    await BaseApi.getTradePlatformData().then((res) => {

      if (res.status == 200 && res.data.data) {
        setTradePlatformData(res.data.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }
  useEffect(() => {
    getConfigApy();
    getStakingPlatformsCharts();
    getStakingStablehodlCharts();
    getTradePlatformData();
  }, [])

  useEffect(() => {
    getListPools();

  }, [param, chainId, activeStatus]);

  const changeStatus = async (value: any) => {
    console.log('value', value);
    setActiveStatus(value);
  }


  let dataPushlish: any = configApy && configApy?.last_update_time ? new Date(configApy?.last_update_time) : '';

  let day: any = dataPushlish ? dataPushlish.toLocaleString('en-gb', { day: "numeric", month: "short" }) : ''

  const textToolTip = <>
    <p><span>The APY is updated weekly based on trailing 1 week return, compounded weekly. This does not represent or guarantee future returns</span></p>
    <p>Last update: {day}</p>
  </>;
  const textToolTipBonus = <>
    <p><span>Bonus APY is around 10% of the weekly base APY, however this is subject to change.</span></p>
  </>;

  const configChart: any = {
    data: stakingPlatformsData,
    xField: (d: any) => formatUTCDate(new Date(d.date)),
    yField: 'value',
    theme: "academy",
    colorField: 'type',
    shapeField: 'smooth',
    axis: {
      x: {
        // labelFormatter: (v:any) => formatUTCDate(new Date(v)),
        labelFill: '#fff', labelFillOpacity: 1, labelFontWeight: 500, labelFontSize: 14, gridLineWidth: 1, gridStroke: '#fff', gridStrokeOpacity: 0.05
      },
      y: {
        labelFormatter: (v: any) => `${v} %`,
        labelFill: '#fff', labelFillOpacity: 1, labelFontWeight: 500, labelFontSize: 14, gridLineWidth: 1, gridStroke: '#fff', gridStrokeOpacity: 0.05
      },
    },
    tooltip: (
      d: any, // 每一个数据项
      index: any, // 索引
      data: any, // 完整数据
      column: any, // 通道
    ) => ({
      value: `${new Intl.NumberFormat("ja-JP").format(column.y.value[index])}`,
    }),
    legend: {
      color: {
        layout: {
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        },
        itemLabelFill: '#fff',
        itemValueFill: '#fff',
        itemLabelFontSize: 16
      },

    },
    interaction: {
      tooltip: {
        items: [
          { field: 'date', valueFormatter: (d: any) => formatUTCDate(new Date(d)) },
        ]
      }
    },
    scale: {
      y: { nice: true },
      color: {
        // domain: ['CLR', 'FEW', 'SCT', 'BKN', 'OVC', 'VV 1'],
        range: ['#7fb8eb', '#F4664A', '#FAAD14', '#4e63ea']
      }
    },
    style: {
      lineWidth: 2
    },
  };
  const configChartStablehodl: any = {
    data: stakingStablehodlData,
    xField: (d: any) => formatUTCDate(new Date(d.date)),
    yField: 'value',
    theme: "academy",
    colorField: 'type',
    shapeField: 'smooth',
    axis: {
      x: { labelFill: '#fff', labelFillOpacity: 1, labelFontWeight: 500, labelFontSize: 14, gridLineWidth: 1, gridStroke: '#fff', gridStrokeOpacity: 0.05 },
      y: {
        labelFormatter: (v: any) => `${v} %`,
        labelFill: '#fff', labelFillOpacity: 1, labelFontWeight: 500, labelFontSize: 14, gridLineWidth: 1, gridStroke: '#fff', gridStrokeOpacity: 0.05
      },
    },
    tooltip: (
      d: any, // 每一个数据项
      index: any, // 索引
      data: any, // 完整数据
      column: any, // 通道
    ) => ({
      value: `${new Intl.NumberFormat("ja-JP").format(column.y.value[index])}`,
    }),
    interaction: {
      tooltip: { items: [{ channel: 'y' }, { channel: 'x' }] }
    },
    legend: {
      color: {
        layout: {
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        },
        itemLabelFill: '#fff',
        itemValueFill: '#fff',
        itemLabelFontSize: 16
      },

    },
    scale: {
      y: { nice: true },
      color: {
        range: ['#7fb8eb', '#F4664A', '#FAAD14', '#4e63ea']
      }
    },
    style: {
      lineWidth: 2
    },
    animate: { enter: { type: 'fadeIn' } }
  };

  const columns: any = [

    {
      title: <div className="text-center">POSITION</div>,
      dataIndex: 'points',
      render: (text: any, record: any) => {

        return (
          <div className="text-white weight-500 d-flex-left">
            <img src={record?.logo} width={20} />
            {record?.name}
          </div>
        )
      }
    },
    {
      title: <div className="text-center">ALLOCATION</div>,
      dataIndex: 'reward',
      render: (text: any, record: any) => {
        return (
          <div className="text-info text-center">
            {record?.allocations > 0 ? new Intl.NumberFormat("ja-JP").format(record?.allocations) : 0} %
          </div>
        )
      }

    }];

  return (
    <>
      <div className="app-page bg-main">
        <div className="page-content">
          <HeaderApp />
          <div className="container">
            <h2 className="text-center mb-15 head-title">Pool Staking</h2>
            <div className="mt-15 select-fiter text-right">
              <Select
                value={activeStatus}
                placeholder="Select status..."
                onChange={changeStatus}
                style={{ width: 180 }}
                suffixIcon={<CaretDownOutlined rev={undefined} />}
              >
                <Option value="">
                  <img src="" alt="" />{" "}
                  <span className="text-chain">All</span>
                </Option>
                <Option value="upcoming">
                  <img src="" alt="" />{" "}
                  <span className="text-chain">Upcoming</span>
                </Option>
                <Option value="live">
                  <img src="" alt="" />{" "}
                  <span className="text-chain">Live</span>
                </Option>
                <Option value="finished">
                  <img src="" alt="" />{" "}
                  <span className="text-chain">Finished</span>
                </Option>
              </Select>
            </div>
            <div className="pool-list">
              <Row gutter={30}>

                {pools.length > 0 ? pools.map((item: any, key: any) => {

                  let nowDate = new Date();
                  let startTime = item?.start_time ? new Date(item?.start_time) : '';
                  let endTime = item?.end_time ? new Date(item?.end_time) : '';

                  let status = 'live';
                  if (item?.amount > 0 && item?.amount >= item?.pool_cap) {
                    status = 'finished';
                  }

                  let statusTime = 'upcoming';
                  if (startTime != '' && nowDate > startTime && endTime > nowDate) {
                    statusTime = 'live';
                  }
                  if (startTime != '' && startTime < nowDate && endTime < nowDate) {
                    statusTime = 'finished';
                  }

                  return (
                    <Col xs={24} sm={8} key={`it11-${key}`}>
                      <div className="pool-item" key={`ita-${key}`}>
                        <div className={`flex-left ${statusTime}`}>
                          <div className="circle-black"></div>
                          <span>
                            {item?.amount > 0 && item?.amount >= item?.pool_cap ? (
                              <>
                                filled
                              </>
                            ) : (
                              <>{statusTime}</>
                            )}

                          </span>
                        </div>
                        <div className="flex-left mt-15 mb-5">
                          <div className="pool-logo">
                            <img src="../images/hela-logo.svg" />
                          </div>
                          <div className="pool-title">
                            <div className="text-white font-18 weight-600 mb-10">{item?.name}</div>
                            <div className="text-gray-light">{item?.description}</div>
                          </div>

                        </div>
                        <div className="flex-btw mt-15 mb-5">
                          <span className="text-gray-light">POOL CAP</span>
                          <span className="text-white weight-500">${item?.pool_cap > 0 ? new Intl.NumberFormat("ja-JP").format(item?.pool_cap) : 0}</span>
                        </div>
                        <div className="flex-btw mt-15 mb-5">
                          <span className="text-gray-light">BOOSTED POINTS</span>
                          <span className="text-white weight-500">{item?.boost_points > 0 ? new Intl.NumberFormat("ja-JP").format(item?.boost_points) : 0}x</span>
                        </div>

                        <div className="flex-btw mt-15 mb-5">
                          <span className="text-gray-light">STAKED AMOUNT</span>
                          <span className="text-white weight-500">${item?.amount > 0 ? new Intl.NumberFormat("ja-JP").format(item?.amount) : 0}</span>
                        </div>
                        <div className="flex-btw mt-15 mb-5">
                          {startTime == '' && (
                            <>
                              <span className="text-gray-light">START IN</span>
                              <span className="text-white weight-500">TBC</span>
                            </>
                          )}
                          {startTime != '' && startTime > nowDate && endTime > nowDate && (
                            <>
                              <span className="text-gray-light">START IN</span>
                              <span className="text-white weight-500">{CountdownComponent(item?.start_time)}</span>
                            </>
                          )}
                          {startTime != '' && startTime < nowDate && endTime > nowDate && (
                            <>
                              <span className="text-gray-light">END IN</span>
                              <span className="text-white weight-500">{CountdownComponent(item?.end_time)}</span>
                            </>
                          )}
                          {startTime != '' && startTime < nowDate && endTime < nowDate && (
                            <>
                              <span className="text-gray-light">ENDED</span>
                            </>
                          )}

                        </div>
                        <div className="pool-info  mb-5">
                          <div className="d-flex-left gap-10">
                            <div className="pool-pay">
                              {item?.type != "HELA" && (
                                <>
                                  <img src="../images/usdc.svg" />
                                  <img src="../images/usdt.svg" />
                                </>
                              )}
                              {item?.type == "HELA" && (
                                <img width={25} src="../images/hela-logo.svg" />
                              )}
                            </div>
                            <div className="w-full">
                              <div className="flex-btw">
                                <Tooltip placement="bottom" title={textToolTip}>
                                  <span className="text-gray-light">APY <InfoCircleOutlined /></span>
                                </Tooltip>

                                <span className="text-green weight-500">{configApy?.avg_apy_str}</span>
                              </div>
                              {/* {item?.type == "HELA" && (
                                <div className="flex-btw mt-15">
                                  <Tooltip placement="bottom" title={textToolTipBonus}>
                                    <span className="text-gray-light">Bonus APY <InfoCircleOutlined /></span>
                                  </Tooltip>
                                  
                                  <span className="text-green weight-500">{configApy?.avg_hela_apy_str}</span>
                                </div>
                              )} */}
                              <div className="flex-btw mt-15">
                                <span className="text-gray-light">REWARD</span>
                                <span className="text-white weight-500">HLUSD</span>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="text-center mt-15">
                          <>
                            {startTime == '' && (
                              <button className="btn-green w-full" disabled>Stake</button>
                            )}
                            {startTime != '' && nowDate > startTime && endTime > nowDate && (
                              <button className="btn-green w-full" onClick={() => { window.location.replace(`/stake/${item?.seo}`) }}>Stake</button>
                            )}
                            {startTime != '' && startTime > nowDate && endTime > nowDate && (
                              <button className="btn-green w-full" disabled>Upcoming</button>
                            )}
                            {startTime != '' && startTime < nowDate && endTime < nowDate && (
                              <button className="btn-green w-full" onClick={() => { window.location.replace(`/stake/${item?.seo}`) }}>Ended</button>
                            )}
                          </>
                        </div>

                      </div>
                    </Col>
                  )
                }) : (
                  <Col xs={24}>
                    <div className="text-center">
                      <Empty description={<span className="text-white">Pool not found</span>} />
                    </div>
                  </Col>
                )}

              </Row>
            </div>
            <hr></hr>
            <div className="mt-30 mb-30 border-bot pb-30">
              <Row gutter={30}>
                <Col xs={24}>
                  {stakingStablehodlData.length > 0 && (
                    <>
                      <div className="mb-15 font-20 text-white text-center">StableHodl's APY</div>
                      <Line className="chart-bg" {...configChartStablehodl} />
                    </>
                  )}
                </Col>
                <Col xs={24}>
                  {stakingPlatformsData.length > 0 && (
                    <>
                      <div className="mb-15 font-20 text-white text-center mt-50">StableHodl's APY compared to some of our competitors</div>
                      <Line className="chart-bg" {...configChart} />
                    </>
                  )}
                </Col>
              </Row>
            </div>
            <div className="mb-50 mt-15">
              <div className="chart-position">
                <Row gutter={30}>
                  <Col xs={24} sm={12}>
                    <div className="text-white font-18 text-center">Understanding the Vault Strategies</div>
                    <p className="text-white line-25 text-justify">Deposited assets are deployed into a basket of DeFi protocols, earning a variable yield based on delta-neutral strategies. Periodically, the vault will rebalance allocations across different strategies to better reflect ever-changing market conditions, with adequate risk management.</p>
                  </Col>
                  <Col xs={24} sm={12}>

                    {/* <Table scroll={{y: 500 }} className="table-leaderboard" pagination={false} columns={columns} dataSource={tradePlatformData}/> */}

                    <div className="d-flex-center">
                      <div className="text-white weight-600 text-green">POSITION</div>
                      <div className="text-white weight-600 text-green">ALLOCATION</div>
                    </div>

                    {tradePlatformData.length > 0 && tradePlatformData.map((item: any, key: any) => {

                      return (
                        <div className="d-flex-center mt-15 border-bot" key={`ik-${key}`}>
                          <div className="text-white weight-500 d-flex-left">
                            {item?.logo && item?.logo != '' && (
                              <img src={item?.logo} width={20} />
                            )}

                            <div className="dot-css" title={item?.name}>{item?.name}</div>
                          </div>
                          <div className="text-white weight-500" style={{ width: '50px' }}>
                            <span>{item?.allocations > 0 ? new Intl.NumberFormat("ja-JP").format(item?.allocations) : 0} %</span>
                          </div>
                        </div>
                      )
                    })}

                  </Col>
                </Row>
              </div>
            </div>


          </div>

        </div>
        <Footer />
      </div>

    </>
  );
};
export default StakingList;
