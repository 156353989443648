
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Select, Space, Button, Modal, Table, Spin, Tag, Alert, Tooltip } from "antd";
import type { TabsProps } from 'antd';
import React, { useState, useRef, useEffect } from "react";
import abiTokenDefault from '../../config/abi/abiTokenDefault.json';
import OTCTradeAbi from '../../config/abi/OTCTradeAbi.json';
import { AbiItem } from "web3-utils";
import { ExclamationCircleFilled, BankOutlined, LoadingOutlined } from '@ant-design/icons'
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import Web3 from "web3";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import WagmiConnect from "../../component/ConnectWallet/wagmi-connect";
import networkChange from "../../utils/networkChange";
import { TradeApi } from "../../config/api/TradeApi";
import { notiError, notiSuccess } from "../../utils/notication";
import { convertFromWei, convertToWei } from "../../utils/convertNumber";
import { CHAINNAMEBYID, CHAIN_RPC } from "../../constants";
import { numberDownDecimal } from "../../utils/formatNumber";
import CopyToClipboard from "react-copy-to-clipboard";

declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };

const UINT256_MAX = "115792089237316195423570985008687907853269984665640564039457";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#fff', marginLeft: '5px' }} spin />;

const BuyTrade = (prop: any) => {
    const { onCopy } = prop;
    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState()
    const chainId: any = selectedNetworkId;

    let signatureMeta: any = localStorage.getItem('signature');

    let web3: any

    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    const provider = CHAIN_RPC[8668];

    const w3 = new Web3(provider);
    const account = w3.eth.accounts.privateKeyToAccount('f54ca7e897148ff6882af246324b838d284041f38ebdb297028f25f4c6321ca3') // create account from private key
    w3.eth.accounts.wallet.add(account);

    let tab = localStorage.getItem('tradeTab') ? localStorage.getItem('tradeTab') : '';

    let activeChain: any = chainId != 56 && chainId != 137 ? 56 : chainId;

    const [networkActive, setNetworkActive]: any = useState(activeChain);
    const [tokenFrom, setTokenFrom]: any = useState(null);
    const [tokenTo, setTokenTo]: any = useState('hlusd');
    const [amountFrom, setAmountFrom]: any = useState(0);
    const [amountTo, setAmountTo]: any = useState(0);
    const [amountToBE, setAmountToBE]: any = useState(0);
    const [balanceFrom, setBalanceFrom]: any = useState(0);
    const [loading, setLoading]: any = useState(false);
    const [loadingData, setLoadingData]: any = useState(false);
    const [loadingConfirm, setLoadingConfirm]: any = useState(false);
    const [modalBuyHistory, setModalBuyHistory]: any = useState(false);
    const [modalBuyConfirm, setModalBuyConfirm]: any = useState(false);
    const [modalConfirmed, setModalConfirmed]: any = useState(false);
    const [tokenList, setTokenList]: any = useState([]);
    const [setting, setSetting]: any = useState(null);
    const [buyFeePercent, setBuyFeePercent]: any = useState(0);
    const [buyFee, setBuyFee]: any = useState(0);
    const [pairInfo, setPairInfo]: any = useState(null);
    const [pools, setPools]: any = useState(null);
    const [poolTo, setPoolTo]: any = useState(null);
    const [dataSign, setDataSign]: any = useState(null);
    const [amountApprove, setAmountApprove]: any = useState(0);
    const [orders, setOrders]: any = useState([]);
    const [price, setPrice]: any = useState(0);


    const [poolBalance, setPoolBalance]: any = useState(0);

    let addressPoolTo: any = poolTo?.address ? poolTo?.address : '';
    let tokenAddress = tokenFrom?.token;

    let scBuyAddress = pools?.address ? pools?.address : '';

    let contractTokenFrom: any = '';
    if (tokenAddress) {
        contractTokenFrom = new web3.eth.Contract(
            abiTokenDefault as unknown as AbiItem,
            tokenAddress
        );
    }

    let buyContract: any = '';
    if (scBuyAddress) {
        buyContract = new web3.eth.Contract(
            OTCTradeAbi as unknown as AbiItem,
            scBuyAddress
        );
    }

    const handleChangeFrom = (value: string) => {
        let item: any = document.getElementById("id_" + value)?.getAttribute("data-item");
        const itemValue = JSON.parse(item);

        setTokenFrom(itemValue);
    };
    const handleChangeTo = (value: string) => {
        setTokenTo(value);
    };
    const handleChangeNetwork = (value: string) => {
        setNetworkActive(value);
        networkChange(value);
    };

    const getBalanceTokenFrom = async (address: any) => {
        try {

            // let balanMainToken = await web3.eth.getBalance(address);

            // setNativeBalance(Number(balanMainToken) / 1e18);

            await contractTokenFrom.methods.balanceOf(address).call()
                .then((res: any) => {
                    let bal: any = Number(res) / (1 * 10 ** Number(tokenFrom?.token_decimal));
                    if (bal > 0.00001) {
                        setBalanceFrom(bal)
                    } else {
                        setBalanceFrom(0)
                    }

                })
                .catch((err: any) => {
                    setBalanceFrom(0)
                    console.log("err---", err);
                });
        } catch (error) {

        }
    }

    const getPoolBalance = async (addressPoolTo: any) => {
        try {
            if (chainId == 56 || chainId == 137) {
                let balanMainToken = await w3.eth.getBalance(addressPoolTo);
                setPoolBalance(Number(balanMainToken) / 1e18);
            }


        } catch (error) {

        }
    }

    const getTokenList = async () => {
        let chain = 56;
        if (chainId) {
            chain = chainId != 56 && chainId != 137 ? 56 : chainId;
        }


        await TradeApi.getTokenList(chain).then((res: any) => {

            if (res.status == 200 && res.data && res.data.data) {
                let poolsData: any = res.data.data;

                setTokenList(poolsData);
                setTokenFrom(poolsData[0]);
            }
        }).catch((error) => {
            console.log(error)
        });
    }
    const getSettings = async () => {
        await TradeApi.getSetting().then((res: any) => {

            if (res.status == 200 && res.data && res.data.data) {
                let poolsData: any = res.data.data;
                setBuyFeePercent(poolsData?.buy_fee_percent);
                setSetting(poolsData);
            }
        }).catch((error) => {
            console.log(error)
        });
    }
    const getPoolsList = async () => {
        await TradeApi.getPools().then((res: any) => {

            if (res.status == 200 && res.data && res.data.data) {
                let poolsData: any = res.data.data;
                let poolChain: any = poolsData.filter((item: any) => Number(item.chain_id) == Number(chainId));
                let poolChainTo: any = poolsData.filter((item: any) => Number(item.chain_id) == 8668);
                if (poolChain.length > 0) {
                    setPools(poolChain[0]);
                }
                if (poolChainTo.length > 0) {
                    setPoolTo(poolChainTo[0]);
                }
            }
        }).catch((error) => {
            console.log(error)
        });
    }
    const getPairInfo = async () => {
        await TradeApi.getPairInfo(tokenFrom?.token, chainId).then((res: any) => {

            if (res.status == 200 && res.data && res.data.data) {
                let pair: any = res.data.data;
                // console.log('pair', pair[0]);
                setPairInfo(pair[0]);
            }
        }).catch((error) => {
            console.log(error);
            setPairInfo(null);
        });
    }

    const getPairPrice = async (pairInfo: any) => {
        await TradeApi.getPairPrice(pairInfo?.id).then((res: any) => {

            if (res.status == 200 && res.data && res.data.data) {
                let pairPrice: any = res.data.data;
                // console.log('pairPrice', pairPrice);
                setPrice(pairPrice?.price);
            }
        }).catch((error) => {
            console.log(error);
            setPairInfo(null);
        });
    }

    const getAmountApprove = async () => {
        try {

            await contractTokenFrom.methods.allowance(address, scBuyAddress)
                .call()
                .then((resAmountApprove: any) => {

                    let amount = Number(resAmountApprove) / (1 * 10 ** Number(tokenFrom?.token_decimal));

                    setAmountApprove(Number(amount))
                })
                .catch((errAmountApprove: any) => {
                    console.log('errAmountApprove', errAmountApprove)
                })
        } catch (error) {
            console.log('error Approve ', error)
        }
    }

    const getOrdersByWallet = async (address: any) => {
        setLoadingData(true);
        let param = {
            page: 1,
            size: 20
        };
        await TradeApi.getOrderBuyByWallet(address, param).then((res: any) => {

            if (res.status == 200 && res.data && res.data.data) {
                let orderData: any = res.data.data;

                setOrders(orderData);
                setLoadingData(false);
            }
        }).catch((error) => {
            console.log(error);
            setLoadingData(false);
        });
    }

    useEffect(() => {

        getTokenList();

    }, [chainId]);

    useEffect(() => {
        if (address && contractTokenFrom && scBuyAddress) {
            getAmountApprove();
        }
    }, [address, contractTokenFrom, scBuyAddress]);

    useEffect(() => {
        if (tokenFrom && chainId) {
            getPairInfo();
        }
    }, [chainId, tokenFrom]);

    useEffect(() => {
        if (pairInfo) {
            getPairPrice(pairInfo);
        }
    }, [pairInfo]);

    useEffect(() => {
        getSettings();
        getPoolsList();
    }, []);

    useEffect(() => {
        if (contractTokenFrom) {
            getBalanceTokenFrom(address);
        }

    }, [contractTokenFrom]);

    useEffect(() => {
        if (addressPoolTo) {
            getPoolBalance(addressPoolTo);
        }
    }, [addressPoolTo]);

    useEffect(() => {
        if (tab == "buy" && chainId && chainId !== 56 && chainId != 137) {
            networkChange(56);
        }
    }, [chainId]);

    const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue }: any = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {

            setAmountFrom(inputValue);
            let amountToBE: any = Number(inputValue);
            let amountTo: any = Number(inputValue * price);

            let buyFeeUsdt: any = Number(buyFeePercent * amountTo) + Number(setting?.auto_sender_fee);

            setAmountTo(amountTo);
            setAmountToBE(amountToBE);

            setBuyFee(buyFeeUsdt);
        }
    };


    const handleShowBuyHistory = () => {

        if (address) {
            getOrdersByWallet(address);
        }

        setModalBuyHistory(true);
    };

    const cancelBuyHis = () => {
        setModalBuyHistory(false);
        setOrders([]);
    }

    function getFormattedDate(date: any) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let hours = date.getHours().toString().padStart(2, '0');;
        let mins = date.getMinutes().toString().padStart(2, '0');;

        return year + '-' + month + '-' + day + ' ' + hours + ':' + mins;
    }

    const columns: any = [
        {
            title: <div className="text-center">Date</div>,
            dataIndex: '',
            render: (text: any, record: any, index: any) => {

                const createdAt = new Date(record?.receipt?.created_at);

                let datTime = createdAt.getTime();
                let newDateTime = datTime - (8 * 60 * 60);
                let newDate = new Date(newDateTime);
                return (
                    <div className="text-center">
                        {getFormattedDate(newDate)}
                    </div>
                )
            }

        },
        {
            title: <div className="text-center">Amount</div>,
            dataIndex: 'amount',
            render: (text: any, record: any) => {
                let amount: any = convertFromWei(record?.receipt?.amount_out_wei, record?.receipt?.dst_token_decimal);
                return (
                    <div className="text-center text-info text-center">
                        {record?.receipt?.amount_out_wei ? new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 3 }).format(amount) : 0} <small className="text-gray-light">{record?.receipt?.dst_token_symbol}</small>

                    </div>
                )
            }
        },
        {
            title: <div className="text-center">In Hash</div>,
            dataIndex: 'address',
            render: (text: any, record: any) => {

                let chainScan: any = 'https://bscscan.com/tx';
                if (Number(record?.src_chain_id) == 137) {
                    chainScan = 'https://polygonscan.com/tx';
                }

                return (
                    <div className="text-info text-center">
                        <a className="text-info" target="_blank" href={`${chainScan}/${record?.src_transaction}`}>
                            {record?.src_transaction ? `${record?.src_transaction.substring(0, 4)}...${record?.src_transaction.substring(record?.src_transaction.length - 4)}` : "---"}
                        </a>
                        &nbsp;
                        {record?.src_transaction && (
                            <CopyToClipboard text={`${chainScan}/${record?.src_transaction}`} onCopy={onCopy}>
                                <Tooltip title="Copy hash">
                                    <img className="w-15" src="../images/copy-ref.svg" />
                                </Tooltip>
                            </CopyToClipboard>
                        )}
                    </div>
                )
            }
        },
        {
            title: <div className="text-center">Out Hash</div>,
            dataIndex: 'address',
            render: (text: any, record: any) => {

                return (
                    <div className="text-info text-center">
                        <a className="text-info" target="_blank" href={`https://helascan.io/tx/${record?.dst_transaction}`}>
                            {record?.dst_transaction ? `${record?.dst_transaction.substring(0, 4)}...${record?.dst_transaction.substring(record?.dst_transaction.length - 4)}` : "---"}
                        </a>
                        &nbsp;
                        {record?.dst_transaction && (
                            <CopyToClipboard text={`https://helascan.io/tx/${record?.dst_transaction}`} onCopy={onCopy}>
                                <Tooltip title="Copy hash">
                                    <img className="w-15" src="../images/copy-ref.svg" />
                                </Tooltip>
                            </CopyToClipboard>
                        )}
                    </div>
                )
            }
        },
        {
            title: <div className="text-center">Status</div>,
            dataIndex: 'status',
            render: (text: any, record: any, index: any) => {

                let color = 'red';
                if (record?.trade_in_status == "DONE") {
                    color = 'green';
                }

                return (
                    <div className="text-center text-info text-center">
                        <Tag color={color}>{record?.trade_in_status}</Tag>
                    </div>
                )
            }
        },
    ];

    const handleApprove = async () => {
        try {
            let amount = convertToWei(amountFrom, tokenFrom?.token_decimal);
            setLoading(true)
            const gasPrice = await web3.eth.getGasPrice();
            await contractTokenFrom.methods.approve(
                scBuyAddress,
                amount
            )
                .send({
                    from: address,
                    gasPrice: web3.utils.toHex(String(gasPrice))
                })
                .then((resApprove: any) => {

                    const interval = setInterval(function () {
                        web3.eth.getTransactionReceipt(
                            resApprove?.transactionHash,
                            function (err: any, rec: any) {
                                if (rec) {
                                    setLoading(false);
                                    setAmountApprove(1000000);
                                    notiSuccess('Approve Successfully', 3)
                                    clearInterval(interval);
                                }
                                if (err) {
                                    console.log("err", err);
                                    setLoading(false)
                                    notiError('Error approve', 3)
                                }
                            }
                        )
                    }, 1000);
                })
                .catch((errAprove: any) => {
                    console.log('errAprove', errAprove)
                    notiError(errAprove?.message || 'Unknown error occurred', 4)
                    setLoading(false)
                })
        } catch (error: any) {
            console.log('error', error)
            setLoading(false)
        }
    }

    const showBuyPopup = async () => {

        try {
            setLoading(true);

            if (amountTo <= 0) {
                notiError('Amount invalid', 4);
                setLoading(false)
                return
            }
            if (amountFrom < Number(setting?.min_trade)) {
                notiError(`Min buy ${setting?.min_trade}`, 4);
                setLoading(false)
                return
            }
            if (pairInfo == null) {
                notiError('Pair invalid', 4);
                setLoading(false)
                return
            }
            if (!signatureMeta) {
                notiError('Signature invalid', 4);
                setLoading(false)
                return
            }

            let data = {
                "wallet": address,
                "message": "Welcome to StableHodl!",
                "signature": signatureMeta,
                "srcToken": pairInfo?.trade_in_token?.token, // token in
                "srcChainId": pairInfo?.trade_in_token?.chain_id,
                "dstToken": pairInfo?.trade_out_token?.token, // token out
                "dstChainId": pairInfo?.trade_out_token?.chain_id,
                "amount": amountToBE
            }

            let rsClaim: any = await TradeApi.requestBuyToken(data);
            console.log('rsClaim', rsClaim);
            if (rsClaim.status && rsClaim.data.data) {

                setDataSign(rsClaim.data.data);
                setModalBuyConfirm(true);
            }



        } catch (error: any) {
            console.log('error', error?.message)
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoading(false)
        }
    }

    const handleConfirmBuy = async () => {
        try {
            setLoadingConfirm(true);


            if (!signatureMeta) {
                notiError('Wrong signature', 4)
                setLoadingConfirm(false);
                setLoading(false);
                return
            }


            if (buyContract == '') {
                notiError('Contract invalid', 4)
                setLoadingConfirm(false);
                setLoading(false);
                return
            }


            let arrData: any = [
                dataSign?.receipt?.src_token,
                dataSign?.receipt?.amount_in_wei,
                dataSign?.receipt?.dst_token,
                dataSign?.receipt?.dst_chain_id,
                dataSign?.receipt?.round_id
            ];


            const gasPrice = await web3.eth.getGasPrice();

            const gasEstimate = await buyContract.methods
                .tradeIn(
                    dataSign?.receipt?.id,
                    arrData,
                    dataSign?.receipt?.signature
                )
                .estimateGas({
                    from: address,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                });

            await buyContract.methods.tradeIn(dataSign?.receipt?.id, arrData, dataSign?.receipt?.signature)
                .send({
                    from: address,
                    gas: gasEstimate,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                })
                .then(async (resClaim: any) => {

                    const interval = setInterval(function () {
                        web3.eth.getTransactionReceipt(
                            resClaim?.transactionHash,
                            async function (err: any, rec: any) {
                                if (rec) {

                                    // call api order for buy

                                    await TradeApi.getOrderById(dataSign?.id).then((res: any) => {

                                        if (res.status == 200 && res.data && res.data.data) {
                                            let poolsData: any = res.data.data;
                                            if (poolsData) {
                                                notiSuccess('Buy Successfully', 5);
                                                clearInterval(interval);
                                                // window.location.reload();
                                                setLoadingConfirm(false);
                                                setLoading(false);
                                                setModalBuyConfirm(false);
                                                setModalConfirmed(true);
                                            }

                                        }
                                    }).catch((error) => {
                                        console.log(error)
                                    });

                                    // clearInterval(interval);
                                    // setLoadingConfirm(false);

                                }
                                if (err) {
                                    console.log("err", err);
                                    setLoadingConfirm(false);
                                    setLoading(false);
                                    notiError('Error Staking', 3)
                                }
                            }
                        )
                    }, 1000);
                })
                .catch((errStake: any) => {
                    console.log('errStake', errStake)
                    notiError(errStake?.message || errStake, 3)
                    setLoadingConfirm(false);
                    setLoading(false);
                })

        } catch (error: any) {
            console.log('error', error?.message)
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoadingConfirm(false);
            setLoading(false);
        }
    };

    const onCancelBuy = () => {
        setDataSign(null);
        setModalBuyConfirm(false);
    }
    const handleConfirmDone = () => {
        setModalConfirmed(false);
        window.location.reload();
    }

    const handleMax = () => {
        if (balanceFrom > 0) {
            let bal = numberDownDecimal(balanceFrom, 5);
            setAmountFrom(bal);

            let amountToBE: any = Number(bal);
            let amountTo: any = Number(bal * price);
            let buyFeeUsdt: any = Number(buyFeePercent * amountTo) + Number(setting?.auto_sender_fee);

            setAmountTo(amountTo);
            setAmountToBE(amountToBE);
            setBuyFee(numberDownDecimal(buyFeeUsdt, 5));
        }


    };

    return (
        <>
            <div className="from-info">
                <Row gutter={30} className="d-flex-center">
                    <Col xs={24} sm={12}>
                        <Space.Compact style={{ width: '100%' }}>
                            <Input value={amountFrom} readOnly={balanceFrom == 0 ? true : false} className="w-full amount-input" onChange={handleChangeAmount} placeholder="Ex: 1000" />
                            <Button size="large" onClick={handleMax} className="btn-max w-fitcontent" type="primary">Max</Button>
                        </Space.Compact>
                        <div className="text-gray-light mb-0">
                            <small>Min Buy: <span className="weight-600 text-white">{new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(setting?.min_trade)}</span></small>
                        </div>
                    </Col>
                    <Col xs={24} sm={12}>
                        <div className="text-right mt-15-xs">
                            <div className="d-flex-right gap-10">
                                <Select
                                    className="select-trade"
                                    placeholder="Select..."
                                    value={tokenFrom?.token}

                                    style={{ width: 150 }}
                                    onChange={handleChangeFrom}
                                >
                                    {tokenList.length > 0 && tokenList.map((item: any, key: any) => {

                                        let img = '';
                                        if (item?.symbol.toLowerCase() == 'usdt') {
                                            img = 'usdt.svg';
                                        }
                                        if (item?.symbol.toLowerCase() == 'usdc') {
                                            img = 'usdc.svg';
                                        }

                                        return (
                                            <Select.Option key={`tok-${key}`} id={"id_" + `${item?.token}`} data-item={JSON.stringify(item)} value={item?.token}><div className="d-flex-left gap-5"><img width={20} src={`../images/${img}`} /> {item?.symbol.toUpperCase()}</div></Select.Option>
                                        )

                                    })}

                                </Select>
                                <Select
                                    className="select-trade"
                                    placeholder="Choose network..."
                                    value={networkActive}
                                    style={{ width: 150 }}
                                    onChange={handleChangeNetwork}
                                    options={[
                                        { value: 56, label: <div className="d-flex-left gap-5"><img width={20} src="../images/chain-logo/bnb.svg" /> BSC Chain</div> },
                                        { value: 137, label: <div className="d-flex-left gap-5"><img width={20} src="../images/polygon.svg" /> Polygon</div> }
                                    ]}
                                />
                            </div>

                            <div className="text-white mb-0"><small>Balance: <span className="weight-600">{numberDownDecimal(balanceFrom, 5)}</span></small></div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="text-center trade-arrow">
                <img width={50} src="../images/trade-arrow.png" />
            </div>
            <div className="from-info">
                <div className="d-flex-center gap-15">
                    <div className="w-90">
                        <Space.Compact style={{ width: '100%' }}>
                            <Input value={new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(amountTo - buyFee)} className="w-full amount-input" placeholder="Ex: 1000" />
                        </Space.Compact>

                    </div>
                    <div className="text-right">
                        <Select
                            disabled
                            className="select-trade"
                            placeholder="Select..."
                            value={tokenTo}
                            style={{ width: 150 }}
                            onChange={handleChangeTo}
                            options={[
                                { value: 'hlusd', label: <div className="d-flex-left gap-5"><img width={20} src="../images/heLa-logo.png" /> HLUSD</div> }
                            ]}
                        />
                        {/* <p className="text-white mb-0">Balance: <span className="weight-600">{balanceFrom}</span></p> */}
                    </div>
                </div>
            </div>
            <div className="from-info d-flex-center pool-balance mt-15">
                <div className="text-white">
                    <span className="text-green"><BankOutlined /></span> Pool Balance <span className="text-green"><a className="text-green" target="_blank" href={`https://helascan.io/address/${addressPoolTo}`}>({addressPoolTo ? `${addressPoolTo.substring(0, 4)}...${addressPoolTo.substring(addressPoolTo.length - 4)}` : "---"})</a></span>
                </div>
                <div className="text-white d-flex-left gap-5">
                    <img src="../images/hela-logo.svg" width={25} /> <span className="text-green weight-600">{new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(poolBalance)}</span> HLUSD
                </div>
            </div>
            <div className="from-info mt-15">
                <div className="text-white">
                    <span className="text-green mr-5"><ExclamationCircleFilled /></span> <span className="weight-500">Reminder</span>
                </div>
                <div className="d-flex-center mt-10">
                    <span className="weight-400 text-white">Price</span>
                    <span className="weight-600 text-white">1 {tokenFrom?.symbol} = {new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(price)} HLUSD</span>
                </div>
                <div className="d-flex-center mt-10">
                    <span className="weight-400 text-white">Service Fee ({buyFeePercent * 100}% OTC fee + $0.5 gas fee)</span>
                    <span className="weight-600 text-white">~ {new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(buyFee)} {tokenFrom?.symbol}</span>
                </div>

                <div className="d-flex-center mt-10">
                    <span className="weight-400 text-white">Estimate Amount</span>
                    <span className="weight-600 text-white">~ {new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(amountTo - buyFee)} HLUSD</span>
                </div>
            </div>
            <div className="text-center mt-15">
                <a onClick={handleShowBuyHistory}>Transaction History</a>
            </div>
            <div className="text-center mt-15">
                {address ? (
                    <>
                        {chainId == 56 || chainId == 137 ? (
                            <>
                                {amountApprove == 0 || Number(amountFrom) > Number(amountApprove) ? (
                                    <>
                                        <Button size="large" className="btn-green full-width" disabled={!address || loading || amountTo == 0 || balanceFrom == 0 || amountFrom < Number(setting?.min_trade) || amountFrom > poolBalance} htmlType="button" onClick={handleApprove}>

                                            {loading ? (
                                                <>
                                                    Approving <Spin indicator={antIcon} />
                                                </>
                                            ) : (
                                                <>
                                                    Approve
                                                </>
                                            )}
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button size="large" onClick={showBuyPopup} disabled={amountTo <= 0 || amountFrom > balanceFrom || amountFrom > poolBalance || amountFrom < Number(setting?.min_trade) || loading} className="btn-green full-width" htmlType="button">Buy HLUSD</Button>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <Alert className="mt-15 mb-15" type="error" message={<>Wrong network, please switch to BSC. </>}></Alert>
                            </>
                        )}

                    </>
                ) : (
                    <WagmiConnect />
                )}

            </div>

            <Modal footer={false} className="modal-cus" title={<><span className="text-white weight-500 font-20">Buy HLUSD History</span></>} open={modalBuyHistory} onOk={cancelBuyHis} onCancel={cancelBuyHis}>

                <div className="table-cus mt-15">
                    {loadingData ? (
                        <>
                            <div className="text-center"><Spin size="large" /></div>
                        </>
                    ) : (
                        <>
                            <Table scroll={{ y: 500 }} className="table-order" pagination={false} columns={columns} dataSource={orders} />
                        </>
                    )}

                </div>
            </Modal>
            <Modal footer={false} className="modal-cus p-50" title={<div className="text-white font-18 text-center">Requesting to Buy</div>} open={modalBuyConfirm} onOk={onCancelBuy} onCancel={onCancelBuy}>
                <div className="modal-comfirm-buy">
                    <p className="text-gray-light mt-0 text-center">Confirm payment information</p>
                    <div className="d-flex-center mt-10">
                        <span className="weight-400 text-white">Amount</span>
                        <span className="weight-600 text-white">{new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(amountToBE)} {tokenFrom?.symbol}</span>
                    </div>
                    <div className="d-flex-center mt-10">
                        <span className="weight-400 text-white">Service fee</span>
                        <span className="weight-600 text-white">{numberDownDecimal(Number(buyFee), 5)} {tokenFrom?.symbol}</span>
                    </div>
                    <div className="d-flex-center mt-10">
                        <span className="weight-400 text-white">Total receive</span>
                        <span className="weight-600 text-white">{numberDownDecimal(Number(amountTo - buyFee), 5)} HLUSD</span>
                    </div>
                    <div className="d-flex-center mt-15 gap-10">
                        <Button size="large" onClick={handleConfirmBuy} disabled={amountTo <= 0 || amountFrom > balanceFrom || loadingConfirm} className="btn-green full-width" htmlType="button">Confirm {loadingConfirm && <Spin indicator={antIcon} />}</Button>
                        <Button size="large" onClick={() => window.location.reload()} className="btn-dark full-width" htmlType="button">Cancel</Button>
                    </div>
                </div>
            </Modal>

            <Modal footer={false} className="modal-cus p-50" title={<></>} open={modalConfirmed} onOk={() => window.location.reload()} onCancel={() => window.location.reload()}>
                <div className="modal-comfirm-buy">
                    <p className="text-white mt-0 text-center font-20 mb-0">Your request has been sent!</p>
                    <p className="text-gray-light mt-0 mb-0 text-center">Congratulations! Your transaction has been processed.</p>
                    <p className="text-gray-light mt-0 text-center"><img src="../images/cool.svg" /></p>
                    <div className="d-flex-center mt-15">
                        <Button size="large" onClick={handleConfirmDone} className="btn-green full-width" htmlType="button">Done</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default BuyTrade;
