
import { Col, message, Tabs, Layout, Row, Tooltip } from "antd";

import React, { useState, useRef, useEffect } from "react";

import { RightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import Slider from "react-slick";
import { Line } from '@ant-design/charts';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BaseApi } from "../../config/api/BaseApi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { formatUTCDate } from "../../utils/formatDate";


const Homepage = () => {

  const { address } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState()
  const chainId: any = selectedNetworkId

  const [configApy, setConfigApy]: any = useState();
  const [statistics, setStatistics]: any = useState();

  const [totalYield, setTotalYield]: any = useState(0);

  const [stakingPlatformsData, setStakingPlatformsData]: any = useState([]);
  const [stakingStablehodlData, setStakingStablehodlData]: any = useState([]);

  let settings: any = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 6,
    autoplay: true,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  let settings2: any = {
    dots: true,
    arrows: false,
    slidesToShow: 6,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  const getStakingPlatformsCharts = async () => {
    await BaseApi.getStakingPlatformsCharts().then((res) => {

      if (res.status == 200 && res.data.data) {
        setStakingPlatformsData(res.data.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }
  const getStakingStablehodlCharts = async () => {
    await BaseApi.getStakingStablehodlCharts().then((res) => {

      if (res.status == 200 && res.data.data) {
        setStakingStablehodlData(res.data.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  const getStatistics = async () => {
    await BaseApi.getStatistics().then((res) => {
      if (res.status == 200 && res.data.data) {
        setStatistics(res.data.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  // const getExpectedReturns = async () => {
  //     await BaseApi.getExpectedReturns().then((res)=>{
  //       let total_yield:any = 0;
  //       let sizeToSum:any = 0;
  //       if(res.status == 200 && res.data.data){
  //         sizeToSum = res.data.data.length - 1;

  //         let lastItem:any = res.data.data[sizeToSum];
  //         total_yield = lastItem?.expected_returns;
  //         setExpectedReturns(res.data.data);
  //       }
  //       if(total_yield > 0 && sizeToSum > 0){
  //         let totalSizeToSum:any = 1000;

  //         let totalYield:any = total_yield - totalSizeToSum;
  //         setTotalYield(totalYield);
  //       }

  //     }).catch((error)=>{
  //       console.log(error)
  //     });
  // }

  const getConfigApy = async () => {
    await BaseApi.getConfigApy().then((res) => {
      if (res.status == 200 && res.data.data) {
        setConfigApy(res.data.data);
      }
    }).catch();
  }

  useEffect(() => {
    getStakingPlatformsCharts();
    getStakingStablehodlCharts();
  }, [])

  useEffect(() => {
    getConfigApy();
    getStatistics();
    // getExpectedReturns();
  }, []);


  let dataPushlish: any = configApy && configApy?.last_update_time ? new Date(configApy?.last_update_time) : '';

  let day: any = dataPushlish ? dataPushlish.toLocaleString('en-gb', { day: "numeric", month: "short" }) : ''

  const text = <>
    <p><span>The APY is updated weekly based on trailing 1 week return, compounded weekly. This does not represent or guarantee future returns</span></p>
    <p>Last update: {day}</p>
  </>;

  const configChart: any = {
    data: stakingPlatformsData,
    xField: (d: any) => formatUTCDate(new Date(d.date)),
    yField: 'value',
    theme: "academy",
    colorField: 'type',
    shapeField: 'smooth',
    axis: {
      x: {
        // labelFormatter: (v:any) => formatUTCDate(new Date(v)),
        labelFill: '#fff', labelFillOpacity: 1, labelFontWeight: 500, labelFontSize: 14, gridLineWidth: 1, gridStroke: '#fff', gridStrokeOpacity: 0.05
      },
      y: {
        labelFormatter: (v: any) => `${v} %`,
        labelFill: '#fff', labelFillOpacity: 1, labelFontWeight: 500, labelFontSize: 14, gridLineWidth: 1, gridStroke: '#fff', gridStrokeOpacity: 0.05
      },
    },
    tooltip: (
      d: any, // 每一个数据项
      index: any, // 索引
      data: any, // 完整数据
      column: any, // 通道
    ) => ({
      value: `${new Intl.NumberFormat("ja-JP").format(column.y.value[index])}`,
    }),
    legend: {
      color: {
        layout: {
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        },
        itemLabelFill: '#fff',
        itemValueFill: '#fff',
        itemLabelFontSize: 16
      },

    },
    interaction: {
      tooltip: {
        items: [
          { field: 'date', valueFormatter: (d: any) => formatUTCDate(new Date(d)) },
        ]
      }
    },
    scale: {
      y: { nice: true },
      color: {
        // domain: ['CLR', 'FEW', 'SCT', 'BKN', 'OVC', 'VV 1'],
        range: ['#7fb8eb', '#F4664A', '#FAAD14', '#4e63ea']
      }
    },
    style: {
      lineWidth: 2
    },
  };
  const configChartStablehodl: any = {
    data: stakingStablehodlData,
    xField: (d: any) => formatUTCDate(new Date(d.date)),
    yField: 'value',
    theme: "academy",
    colorField: 'type',
    shapeField: 'smooth',
    axis: {
      x: { labelFill: '#fff', labelFillOpacity: 1, labelFontWeight: 500, labelFontSize: 14, gridLineWidth: 1, gridStroke: '#fff', gridStrokeOpacity: 0.05 },
      y: {
        labelFormatter: (v: any) => `${v} %`,
        labelFill: '#fff', labelFillOpacity: 1, labelFontWeight: 500, labelFontSize: 14, gridLineWidth: 1, gridStroke: '#fff', gridStrokeOpacity: 0.05
      },
    },
    tooltip: (
      d: any, // 每一个数据项
      index: any, // 索引
      data: any, // 完整数据
      column: any, // 通道
    ) => ({
      value: `${new Intl.NumberFormat("ja-JP").format(column.y.value[index])}`,
    }),
    interaction: {
      tooltip: { items: [{ channel: 'y' }, { channel: 'x' }] }
    },
    legend: {
      color: {
        layout: {
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        },
        itemLabelFill: '#fff',
        itemValueFill: '#fff',
        itemLabelFontSize: 16
      },

    },
    scale: {
      y: { nice: true },
      color: {
        range: ['#7fb8eb', '#F4664A', '#FAAD14', '#4e63ea']
      }
    },
    style: {
      lineWidth: 2
    },
    animate: { enter: { type: 'fadeIn' } }
  };


  return (
    <>
      <div className="app-page">
        <HeaderApp />
        <div className="container">
          <div className="banner">
            <h1>Stake and Earn</h1>
            <div className="banner-btn">
              <button className="btn-green" onClick={() => window.location.replace('/stakes')} type="button">Stake now <RightOutlined /></button>
              <a href="https://docs.stablehodl.com" target="_blank" className="link-a">Documentation <RightOutlined /></a>
            </div>
            <div className="banner-img">
              <div className="banner-statistics">
                <div className="timer">
                  <div className="time-grid">
                    <Row gutter={30}>
                      <Col xs={24} sm={6}>
                        <Tooltip placement="bottom" title={text}>
                          <div className="time-item">

                            <div className="text">
                              <span>APY</span>
                              <InfoCircleOutlined />
                            </div>
                            <div className="num">{configApy?.avg_apy_str}</div>
                          </div>
                        </Tooltip>
                      </Col>
                      <Col xs={24} sm={6}>
                        <div className="time-item">
                          <div className="text">TVL</div>
                          <div className="num">${statistics ? new Intl.NumberFormat("ja-JP").format(statistics?.amount) : 0}</div>
                        </div>
                      </Col>
                      <Col xs={24} sm={6}>
                        <div className="time-item">
                          <div className="text text-gray-light">Rewards earned</div>
                          <div className="num">${statistics && statistics?.total_reward ? new Intl.NumberFormat("ja-JP").format(statistics?.total_reward) : 0}</div>
                        </div>
                      </Col>
                      <Col xs={24} sm={6}>
                        <div className="time-item time-item-no-border">
                          <div className="text text-gray-light">Total wallets/depositors</div>
                          <div className="num">{statistics ? new Intl.NumberFormat("ja-JP").format(statistics?.total_user) : 0}</div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-30 mb-30 border-bot pb-30">
            <Row gutter={30}>
              <Col xs={24}>
                {stakingStablehodlData.length > 0 && (
                  <>
                    <div className="mb-15 font-20 text-white text-center">StableHodl's APY</div>
                    <Line className="chart-bg" {...configChartStablehodl} />
                  </>
                )}
              </Col>
              <Col xs={24}>
                {stakingPlatformsData.length > 0 && (
                  <>
                    <div className="mb-15 font-20 text-white text-center mt-50">StableHodl's APY compared to some of our competitors</div>
                    <Line className="chart-bg" {...configChart} />
                  </>
                )}
              </Col>
            </Row>
          </div>
          <div className="ai">
            <div className="ai-title">How StableHodl works?</div>
            <div className="ai-list">
              <img src="../images/hiw.svg" />
            </div>
          </div>
          <div className="roadmap">
            <div className="roadmap-title">Roadmap</div>
            <div className="roadmap-list">
              <Row gutter={0}>
                <Col xs={24} sm={6}>
                  <div className="road-item road-item-active">
                    <div className="road-header">Q1 - 2024</div>
                    <div className="road-line line-green">
                      {/* <img src="../images/road-line.svg" /> */}
                    </div>
                    <div className="road-content road-1">
                      <div className="road-title">Planning & Development</div>
                      <div className="road-des">
                        <ul>
                          <li>Define goals and objectives</li>
                          <li>Conduct market research</li>
                          <li>Develop project plan and timeline</li>
                          <li>Begin technical development and setup</li>
                          <li>Start community building</li>
                        </ul>
                      </div>
                      <div className="road-des-line">
                        <img src="../images/road-line-y-active.svg" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={6}>
                  <div className="road-item road-item-active">
                    <div className="road-header">Q2 - 2024</div>
                    <div className="road-line line-green">
                      {/* <img src="../images/road-line.svg" /> */}
                    </div>
                    <div className="road-content road-2">
                      <div className="road-title">Launch</div>
                      <div className="road-des">
                        <ul>
                          <li>Complete UI/UX design</li>
                          <li>Conduct a security audit</li>
                          <li>Launch staking pool on HeLa and start points accrual</li>
                          <li>Monitor performance and gather feedback</li>
                        </ul>
                      </div>
                      <div className="road-des-line">
                        <img src="../images/road-line-y-active.svg" />
                      </div>
                    </div>

                  </div>
                </Col>
                <Col xs={24} sm={6}>
                  <div className="road-item road-item-active">
                    <div className="road-header">Q3 - 2024</div>
                    <div className="road-line line-green">
                      {/* <img src="../images/road-line.svg" /> */}
                    </div>
                    <div className="road-content road-3 road-active">
                      <div className="road-title">MultiChain Integration & Optimization</div>
                      <div className="road-des">
                        <ul>
                          <li>Opening up pools for EVM networks (Ethereum, BNB and Polygon)</li>
                          <li>Expand marketing and community engagement</li>
                          <li>Optimize platform performance</li>
                          <li>Launch referral program</li>
                        </ul>
                      </div>
                      <div className="road-des-line">
                        <img src="../images/road-line-y-active.svg" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={6}>
                  <div className="road-item">
                    <div className="road-header">Q4 - 2024</div>
                    <div className="road-line">

                    </div>
                    <div className="road-content road-4">
                      <div className="road-title">Phase 2 Development & VC Engagement</div>
                      <div className="road-des">
                        <ul>
                          <li>Run marketing campaigns and enhance brand presence</li>
                          <li>Development of native receipt token, sHLUSD, for DeFi opportunities. </li>
                        </ul>
                      </div>
                      <div className="road-des-line">
                        <img src="../images/road-line-y-active.svg" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="powered">
            <div className="powered-title">Powered by</div>
            <div className="powered-list">
              <a href="https://helalabs.com/" target="_blank"><img src="../images/power.svg" /></a>
            </div>
          </div>
          <div className="ai">
            <div className="ai-list">
              <Row gutter={30}>
                <Col xs={24} sm={8}>
                  <div className="ai-item">
                    <div className="img">
                      <img src="../images/ai-1.png" />
                    </div>
                    <div className="ai-info">
                      <div className="title">
                        Enhance your yield
                      </div>
                      <div className="desc">
                        Stake your stablecoins to achieve sustainable yield generated from arbitrage strategies in digital assets
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={8}>
                  <div className="ai-item">
                    <div className="img">
                      <img src="../images/ai-3.png" />
                    </div>
                    <div className="ai-info">
                      <div className="title">
                        Boosted reward in the HeLa ecosystem
                      </div>
                      <div className="desc">
                        Early stakers get boosted points in the HeLa ecosystem airdrop programme
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={8}>
                  <div className="ai-item">
                    <div className="img">
                      <img src="../images/ai-2.png" />
                    </div>
                    <div className="ai-info">
                      <div className="title">
                        Security and peace of mind
                      </div>
                      <div className="desc">
                        Stablehodl partners with regulated custodians and HLUSD is backed by USDC and equivalent assets
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="partner">
            <div className="partner-title">Partners</div>
            <div className="partner-list">
              <Slider {...settings}>

                <div className="partner-item">
                  <img src="../images/partner/partner-2.png" />
                </div>

                <div className="partner-item">
                  <img src="../images/partner/partner-9.svg" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/partner-3.png" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/partner-4.png" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/partner-5.png" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/partner-6.png" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/partner-7.png" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/partner-8.png" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/partner-10.png" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/partner-11.png" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/partner-12.png" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/partner-13.png" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/arena-logo.png" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/x-world-game.png" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/hyperliquidlogo.svg" style={{ filter: 'brightness(6)' }} />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/logo-kamino.svg" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/logo-jupiter.svg" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/jito_logo_green_small.png" style={{ filter: 'brightness(6)' }} />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/marinade-logo-black.svg" style={{ filter: 'brightness(6)' }} />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/logo-flash.svg" />
                </div>
                <div className="partner-item">
                  <img src="../images/partner/vertex-logo.svg" />
                </div>
                <div className="partner-item d-flex-center">
                  <img src="../images/partner/pendle-logo.png" />
                </div>
                <div className="partner-item d-flex-left">
                  <img src="../images/partner/ethena.svg" />
                  <span className="text-white font-30 weight-700">Ethena</span>
                </div>
              </Slider>
            </div>
          </div>
          <div className="partner">
            <div className="partner-title">Investors</div>
            <div className="partner-list">
              <div className="d-desktop">
                <Slider {...settings2}>
                  <div className="partner-item"></div>
                  <div className="partner-item"></div>
                  <div className="partner-item">
                    <img src="../images/partner/partner-1.svg" />
                  </div>

                  <div className="partner-item">
                    <img src="../images/partner/partner-9.png" />
                  </div>
                  <div className="partner-item"></div>
                  <div className="partner-item"></div>

                </Slider>
              </div>
              <div className="d-mobile">
                <Slider {...settings2}>

                  <div className="partner-item">
                    <img src="../images/partner/partner-1.svg" />
                  </div>

                  <div className="partner-item">
                    <img src="../images/partner/partner-9.png" />
                  </div>

                </Slider>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Homepage;
