
import { Col, message, Tabs, Steps, Form, Button, Input, Spin, Row, Alert, Modal, Space, Table, Tag, Empty, Tooltip } from "antd";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import React, { useState, useRef, useEffect } from "react";
import { notiError, notiSuccess } from '../../../utils/notication';
import { LoadingOutlined, RightOutlined, CheckOutlined } from "@ant-design/icons";
import { BaseApi } from "../../../config/api/BaseApi";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import CopyToClipboard from "react-copy-to-clipboard";

declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#fff', marginLeft: '5px' }} spin />;


const WithdrawHis = (prop: any) => {

    const { configApy, onCopy } = prop;
    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState()
    const chainId: any = selectedNetworkId

    let web3: any;
    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    const [param, setParam]: any = useState({
        page: 1,
        size: 100
    });

    const [historys, setHistorys]: any = useState([]);

    const getHistories = async () => {

        await BaseApi.getUnstakeHistories(param, address).then((res) => {

            if (res.status == 200 && res.data && res.data.data) {

                let datas: any = res.data.data;
                setHistorys(datas);
            } else {
                setHistorys([])
            }
        }).catch((err) => {
            console.log('ClaimInfo', err);
        });
    }

    useEffect(() => {
        if (address) {
            getHistories();
        }

    }, [address]);



    function getFormattedDate(date: any) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let hours = date.getHours().toString().padStart(2, '0');;
        let mins = date.getMinutes().toString().padStart(2, '0');;

        return year + '-' + month + '-' + day + ' ' + hours + ':' + mins;
    }

    const columns: any = [

        {
            title: <><span className="text-gray-light">TYPE</span></>,
            dataIndex: '',
            render: (text: any, record: any) => {
                return (
                    <div className="text-white">
                        Withdraw Fund
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">STATUS</span></>,
            dataIndex: 'status',
            render: (text: any, record: any) => {
                return (
                    <div className="text-info">

                        <>
                            {!record.claimed && record?.claim_sign != "" && (
                                <div className="flex-left">
                                    <div className="dot danger"></div>
                                    <span>Unfinished</span>
                                </div>
                            )}
                            {record.claimed && record.status == "CLAIMED" && (
                                <div className="flex-left">
                                    <div className="dot success"></div>
                                    <span>Completed</span>
                                </div>
                            )}
                            {!record.claimed && record.status == "PROCESSING" && (
                                <div className="flex-left">
                                    <div className="dot warning"></div>
                                    <span>Processing</span>
                                </div>
                            )}
                        </>


                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">AMOUNT</span></>,
            dataIndex: 'amount',
            render: (text: any, record: any) => {
                return (
                    <div className="text-green">
                        {new Intl.NumberFormat("ja-JP").format(record?.amount)} HLUSD
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">HASH</span></>,
            dataIndex: 'hash',
            render: (text: any, record: any) => {
                let expoler: any = 'https://helascan.io/tx/';
                return (
                    <div className="text-info">
                        {record?.hash ?
                            <a href={expoler + record?.hash} target="_blank">{`${record?.hash.substring(0, 4)}...${record?.hash.substring(record?.hash.length - 4)}`}</a>
                            :
                            "---"}
                        &nbsp;
                        {record?.hash && (
                            <CopyToClipboard text={expoler + record?.hash} onCopy={onCopy}>
                                <Tooltip title="Copy hash">
                                    <img className="w-15" src="../images/copy-ref.svg" />
                                </Tooltip>
                            </CopyToClipboard>
                        )}
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">DATE</span></>,
            dataIndex: 'created_at',
            render: (text: any, record: any) => {

                const createdAt = new Date(record?.created_at);

                let datTime = createdAt.getTime();
                let newDateTime = datTime - (8 * 60 * 60);
                let newDate = new Date(newDateTime);
                return (
                    <div className="text-gray-light">
                        {getFormattedDate(newDate)}
                    </div>
                )
            }
        },

    ];



    return (
        <>

            <div className="powered mt-15">
                <div className="powered-title">Withdraw History</div>
                <div className="powered-list">
                    <div className="table-responsive">
                        {historys.length > 0 ? (
                            <>
                                <Table className="table-cus table-leaderboard" columns={columns} dataSource={historys} />
                            </>
                        ) : (
                            <>
                                <Empty description={<span className="text-white">No history</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </>
                        )}

                    </div>
                </div>
            </div>


        </>
    );
};
export default WithdrawHis;
