
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse } from "antd";
import type { TabsProps } from 'antd';
import React, { useState, useRef, useEffect } from "react";

import { RightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import type { CollapseProps } from 'antd';
import BuyTrade from "./buy";
import SellTrade from "./sell";
import ClaimTrade from "./claim";
import { notiSuccess } from "../../utils/notication";


const Trade = () => {

    let tab = localStorage.getItem('tradeTab') ? localStorage.getItem('tradeTab') : '';

    const [tabKey, setTabKey]: any = useState(tab ? tab : 'buy');

    const onCopy = () => {
        notiSuccess('Copied', 3)
    }
    const onChange = (key: string) => {
        setTabKey(key);
        localStorage.setItem('tradeTab', key);
    };

    const items: TabsProps['items'] = [
        {
            key: 'buy',
            label: 'Buy HLUSD',
            children: <BuyTrade onCopy={onCopy} />,
        },
        {
            key: 'sell',
            label: 'Sell HLUSD',
            children: <SellTrade onCopy={onCopy} />,
        },
        {
            key: 'claim',
            label: 'Claim',
            children: <ClaimTrade />,
        }
    ];


    return (
        <>
            <div className="app-trade-page">
                <HeaderApp />
                <div className="container">
                    <div className="trade-page">
                        <h2 className="text-white text-center">Trade HLUSD</h2>
                        <div className="trade-block">
                            <Tabs className="tabs-trade" centered activeKey={tabKey} items={items} onChange={onChange} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
export default Trade;
