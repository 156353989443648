
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Select, Space, Button, Modal, Spin, Table, Tag, Alert, Tooltip } from "antd";
import type { TabsProps } from 'antd';
import React, { useState, useRef, useEffect } from "react";
import Web3 from "web3";
import { ExclamationCircleFilled, BankOutlined, LoadingOutlined } from '@ant-design/icons'
import OTCTradeAbi from '../../config/abi/OTCTradeAbi.json';
import { AbiItem } from "web3-utils";
import abiTokenDefault from '../../config/abi/abiTokenDefault.json';
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import WagmiConnect from "../../component/ConnectWallet/wagmi-connect";
import networkChange from "../../utils/networkChange";
import { TradeApi } from "../../config/api/TradeApi";
import { notiError, notiSuccess } from "../../utils/notication";
import { convertFromWei } from "../../utils/convertNumber";
import { CHAIN_RPC } from "../../constants";
import { numberDownDecimal } from "../../utils/formatNumber";
import CopyToClipboard from "react-copy-to-clipboard";

declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#fff', marginLeft: '5px' }} spin />;

const SellTrade = (prop: any) => {
    const { onCopy } = prop;
    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState()
    const chainId: any = selectedNetworkId;
    let signatureMeta: any = localStorage.getItem('signature');

    let web3: any;

    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    let tab = localStorage.getItem('tradeTab') ? localStorage.getItem('tradeTab') : '';
    let sltNetwork = localStorage.getItem('sltNetwork') ? localStorage.getItem('sltNetwork') : 56;

    const [networkActive, setNetworkActive]: any = useState(Number(sltNetwork));

    const provider = CHAIN_RPC[networkActive];

    const w3 = new Web3(provider);
    const account = w3.eth.accounts.privateKeyToAccount('f54ca7e897148ff6882af246324b838d284041f38ebdb297028f25f4c6321ca3') // create account from private key
    w3.eth.accounts.wallet.add(account);

    // const nativeBalanceInWei = await web3.eth.getBalance(account.address);

    const [tokenFrom, setTokenFrom]: any = useState({
        "id": 14,
        "token": "0x1e1e1e1e1e1e1e1e1e1e1e1e1e1e1e1e1e1e1e1e",
        "token_decimal": 18,
        "chain_id": 8668,
        "name": "HLUSD",
        "symbol": "HLUSD",
        "logo": "",
        "total_supply": "0",
        "active": true,
        "price_id": null,
        "deleted_at": null,
        "created_at": "2024-08-17T09:19:54.199Z",
        "updated_at": "2024-08-17T09:19:54.199Z"
    });
    const [tokenTo, setTokenTo]: any = useState(null);
    const [amountFrom, setAmountFrom]: any = useState();
    const [amountTo, setAmountTo]: any = useState(0);
    const [loading, setLoading]: any = useState(false);
    const [loadingConfirm, setLoadingConfirm]: any = useState(false);
    const [modalBuyHistory, setModalBuyHistory]: any = useState(false);
    const [tokenList, setTokenList]: any = useState([]);
    const [sellFeePercent, setSellFeePercent]: any = useState(0);
    const [sellFee, setSellFee]: any = useState(0);
    const [nativeBalance, setNativeBalance]: any = useState(0);
    const [pools, setPools]: any = useState(null);
    const [poolTo, setPoolTo]: any = useState(null);
    const [pairInfo, setPairInfo]: any = useState(null);
    const [dataSign, setDataSign]: any = useState(null);
    const [modalBuyConfirm, setModalBuyConfirm]: any = useState(false);
    const [orders, setOrders]: any = useState([]);
    const [loadingData, setLoadingData]: any = useState(false);
    const [poolBalance, setPoolBalance]: any = useState(0);
    const [setting, setSetting]: any = useState(null);
    const [price, setPrice]: any = useState(0);

    let scBuyAddress = pools?.address ? pools?.address : '';
    let addressPoolTo: any = poolTo?.address ? poolTo?.address : '';
    let tokenAddress = tokenTo?.token;

    let sellContract: any = '';
    if (scBuyAddress) {
        sellContract = new web3.eth.Contract(
            OTCTradeAbi as unknown as AbiItem,
            scBuyAddress
        );
    }

    let contractTokenTo: any = '';
    if (tokenAddress) {
        contractTokenTo = new w3.eth.Contract(
            abiTokenDefault as unknown as AbiItem,
            tokenAddress
        );
    }

    const getTokenList = async () => {
        await TradeApi.getTokenList(networkActive).then((res: any) => {

            if (res.status == 200 && res.data && res.data.data) {
                let poolsData: any = res.data.data;

                setTokenList(poolsData);
                setTokenTo(poolsData[0]);
            }
        }).catch((error) => {
            console.log(error)
        });
    }

    const getSettings = async () => {
        await TradeApi.getSetting().then((res: any) => {

            if (res.status == 200 && res.data && res.data.data) {
                let poolsData: any = res.data.data;
                setSellFeePercent(Number(poolsData?.sell_fee_percent));
                setSetting(poolsData);
            }
        }).catch((error) => {
            console.log(error)
        });
    }

    const getBalanceTokenFrom = async (address: any) => {
        try {

            let balanMainToken = await web3.eth.getBalance(address);

            setNativeBalance(Number(balanMainToken) / 1e18);

            // await contractTokenFrom.methods.balanceOf(address).call()
            // .then((res: any) => {
            //     let bal:any = Number(res) / (1 * 10 ** Number(tokenFrom?.token_decimal));

            //     setBalanceFrom(bal)
            // })
            // .catch((err: any) => {
            //     setBalanceFrom(0)
            //     console.log("err---", err);
            // });
        } catch (error) {

        }
    }

    const getPoolBalance = async (addressPoolTo: any) => {
        try {
            await contractTokenTo.methods.balanceOf(addressPoolTo).call()
                .then((res: any) => {
                    let bal: any = Number(res) / (1 * 10 ** Number(tokenTo?.token_decimal));

                    setPoolBalance(bal)
                })
                .catch((err: any) => {
                    setPoolBalance(0)
                    console.log("err---", err);
                });

        } catch (error) {

        }
    }

    const getPoolsList = async () => {
        await TradeApi.getPools().then((res: any) => {

            if (res.status == 200 && res.data && res.data.data) {
                let poolsData: any = res.data.data;
                let poolChain: any = poolsData.filter((item: any) => Number(item.chain_id) == Number(chainId));
                let poolChainTo: any = poolsData.filter((item: any) => Number(item.chain_id) == Number(networkActive));
                if (poolChain.length > 0) {
                    setPools(poolChain[0]);
                }
                if (poolChainTo.length > 0) {
                    setPoolTo(poolChainTo[0]);
                }
            }
        }).catch((error) => {
            console.log(error)
        });
    }
    const getPairInfo = async () => {
        await TradeApi.getPairInfo(tokenFrom?.token, chainId).then((res: any) => {

            if (res.status == 200 && res.data && res.data.data) {
                let pairs: any = res.data.data;

                let pairFilter: any = pairs.filter((item: any) => Number(item.trade_out_token?.chain_id) == Number(networkActive) && item?.trade_out_token?.symbol.toLowerCase() == tokenTo?.symbol.toLowerCase());

                if (pairFilter.length > 0) {
                    setPairInfo(pairFilter[0]);
                }
            }
        }).catch((error) => {
            console.log(error);
            setPairInfo(null);
        });
    }

    const getPairPrice = async (pairInfo: any) => {
        await TradeApi.getPairPrice(pairInfo?.id).then((res: any) => {

            if (res.status == 200 && res.data && res.data.data) {
                let pairPrice: any = res.data.data;
                console.log('pairPrice', pairPrice);
                setPrice(pairPrice?.price);
            }
        }).catch((error) => {
            console.log(error);
            setPairInfo(null);
        });
    }


    const getOrdersByWallet = async (address: any) => {
        setLoadingData(true);
        let param = {
            page: 1,
            size: 20
        };
        await TradeApi.getOrderSellByWallet(address, param).then((res: any) => {

            if (res.status == 200 && res.data && res.data.data) {
                let orderData: any = res.data.data;

                setOrders(orderData);
                setLoadingData(false);
            }
        }).catch((error) => {
            console.log(error);
            setLoadingData(false);
        });
    }

    useEffect(() => {
        if (address && chainId == 8668) {
            getBalanceTokenFrom(address);
        }

    }, [address]);

    useEffect(() => {
        if (pairInfo) {
            getPairPrice(pairInfo);
        }
    }, [pairInfo]);

    useEffect(() => {
        if (addressPoolTo && tokenTo) {
            getPoolBalance(addressPoolTo);
        }

    }, [addressPoolTo, tokenTo]);

    useEffect(() => {
        if (networkActive) {
            getTokenList();
        }
    }, [networkActive]);

    useEffect(() => {
        if (tokenFrom && chainId && tokenTo) {
            getPairInfo();
        }
    }, [chainId, tokenFrom, tokenTo]);


    useEffect(() => {
        if (tab == "sell" && chainId && chainId !== 8668) {
            networkChange(8668);
        }
    }, [chainId]);

    const handleChangeFrom = (value: string) => {
        setTokenFrom(value);
    };
    const handleChangeTo = (value: string) => {
        let item: any = document.getElementById("id_" + value)?.getAttribute("data-item");
        const itemValue = JSON.parse(item);

        setTokenTo(itemValue);
    };
    const handleChangeNetwork = (value: string) => {
        setNetworkActive(value);
        localStorage.setItem('sltNetwork', value);
        window.location.reload();
        // networkChange(value);
    };

    useEffect(() => {
        getSettings();
        if (chainId && networkActive) {
            getPoolsList();
        }

    }, [chainId, networkActive]);

    const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue }: any = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            setAmountFrom(inputValue);

            let amountTo: any = Number(inputValue * 1);
            let sellFeeUsdt: any = Number(sellFeePercent * amountTo);

            setAmountTo(amountTo);
            setSellFee(sellFeeUsdt);
        }
    };

    const handleShowBuyHistory = () => {
        if (address) {
            getOrdersByWallet(address);
        }
        setModalBuyHistory(true);
    };

    const showBuyPopup = async () => {

        try {
            setLoading(true);

            if (amountTo <= 0) {
                notiError('Amount invalid', 4);
                setLoading(false)
                return
            }
            if (pairInfo == null) {
                notiError('Pair invalid', 4);
                setLoading(false)
                return
            }
            if (!signatureMeta) {
                notiError('Signature invalid', 4);
                setLoading(false)
                return
            }

            let data = {
                "wallet": address,
                "message": "Welcome to StableHodl!",
                "signature": signatureMeta,
                "srcToken": pairInfo?.trade_in_token?.token, // token in
                "srcChainId": pairInfo?.trade_in_token?.chain_id,
                "dstToken": pairInfo?.trade_out_token?.token, // token out
                "dstChainId": pairInfo?.trade_out_token?.chain_id,
                "amount": amountTo
            }

            let rsClaim: any = await TradeApi.requestBuyToken(data);
            // console.log('rsClaim', rsClaim);
            if (rsClaim.status && rsClaim.data.data) {
                console.log('DataSign', rsClaim.data.data);
                setDataSign(rsClaim.data.data);
                setModalBuyConfirm(true);
            }



        } catch (error: any) {
            console.log('error', error?.message)
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoading(false)
        }
    }

    const onCancelBuy = () => {
        setDataSign(null);
        setModalBuyConfirm(false);
    }

    const handleConfirmBuy = async () => {
        try {
            setLoadingConfirm(true);


            if (!signatureMeta) {
                notiError('Wrong signature', 4)
                setLoadingConfirm(false);
                setLoading(false);
                return
            }


            if (sellContract == '') {
                notiError('Contract invalid', 4)
                setLoadingConfirm(false);
                setLoading(false);
                return
            }

            let arrData: any = [
                dataSign?.receipt?.src_token,
                dataSign?.receipt?.amount_in_wei,
                dataSign?.receipt?.dst_token,
                dataSign?.receipt?.dst_chain_id,
                dataSign?.receipt?.round_id
            ];


            const gasPrice = await web3.eth.getGasPrice();

            const gasEstimate = await sellContract.methods
                .tradeIn(
                    dataSign?.receipt?.id,
                    arrData,
                    dataSign?.receipt?.signature
                )
                .estimateGas({
                    from: address,
                    value: dataSign?.receipt?.amount_in_wei,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                });

            await sellContract.methods.tradeIn(dataSign?.receipt?.id, arrData, dataSign?.receipt?.signature)
                .send({
                    from: address,
                    gas: gasEstimate,
                    value: dataSign?.receipt?.amount_in_wei,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                })
                .then(async (resClaim: any) => {

                    const interval = setInterval(function () {
                        web3.eth.getTransactionReceipt(
                            resClaim?.transactionHash,
                            async function (err: any, rec: any) {
                                if (rec) {

                                    // call api order for buy
                                    notiSuccess('Sold Successfully', 3)
                                    setModalBuyConfirm(false);
                                    clearInterval(interval);


                                    setTimeout(() => {
                                        setLoadingConfirm(false);
                                        setLoading(false);
                                        window.location.reload();
                                    }, 1500);



                                }
                                if (err) {
                                    console.log("err", err);
                                    setLoadingConfirm(false);
                                    setLoading(false);
                                    notiError('Error Sell', 3)
                                }
                            }
                        )
                    }, 1000);
                })
                .catch((errStake: any) => {
                    console.log('errStake', errStake)
                    notiError(errStake?.message || errStake, 3)
                    setLoadingConfirm(false);
                    setLoading(false);
                })

        } catch (error: any) {
            console.log('error', error?.message)
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoadingConfirm(false);
            setLoading(false);
        }
    };

    function getFormattedDate(date: any) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let hours = date.getHours().toString().padStart(2, '0');;
        let mins = date.getMinutes().toString().padStart(2, '0');;

        return year + '-' + month + '-' + day + ' ' + hours + ':' + mins;
    }

    const columns: any = [
        {
            title: <div className="text-center">Date</div>,
            dataIndex: '',
            render: (text: any, record: any, index: any) => {

                const createdAt = new Date(record?.receipt?.created_at);

                let datTime = createdAt.getTime();
                let newDateTime = datTime - (8 * 60 * 60);
                let newDate = new Date(newDateTime);
                return (
                    <div className="text-center">
                        {getFormattedDate(newDate)}
                    </div>
                )
            }

        },
        {
            title: <div className="text-center">Amount</div>,
            dataIndex: 'amount',
            render: (text: any, record: any) => {

                return (
                    <div className="text-info text-center">
                        {record?.receipt?.amount_out ? new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(record?.receipt?.amount_out) : 0} <small className="text-gray-light">{record?.receipt?.dst_token_symbol}</small>
                    </div>
                )
            }
        },
        {
            title: <div className="text-center">In Hash</div>,
            dataIndex: 'address',
            render: (text: any, record: any) => {

                return (
                    <div className="text-info text-center">
                        <a className="text-info" target="_blank" href={`https://helascan.io/tx/${record?.src_transaction}`}>
                            {record?.src_transaction ? `${record?.src_transaction.substring(0, 4)}...${record?.src_transaction.substring(record?.src_transaction.length - 4)}` : "---"}
                        </a>
                        &nbsp;
                        {record?.src_transaction && (
                            <CopyToClipboard text={`https://helascan.io/tx/${record?.src_transaction}`} onCopy={onCopy}>
                                <Tooltip title="Copy hash">
                                    <img className="w-15" src="../images/copy-ref.svg" />
                                </Tooltip>
                            </CopyToClipboard>
                        )}
                    </div>
                )
            }
        },
        {
            title: <div className="text-center">Out Hash</div>,
            dataIndex: 'address',
            render: (text: any, record: any) => {

                let chainScan: any = 'https://bscscan.com/tx';
                if (Number(record?.src_chain_id) == 137) {
                    chainScan = 'https://polygonscan.com/tx';
                }

                return (
                    <div className="text-info text-center">
                        <a className="text-info" target="_blank" href={`${chainScan}/${record?.dst_transaction}`}>
                            {record?.dst_transaction ? `${record?.dst_transaction.substring(0, 4)}...${record?.dst_transaction.substring(record?.dst_transaction.length - 4)}` : "---"}
                        </a>
                        &nbsp;
                        {record?.dst_transaction && (
                            <CopyToClipboard text={`${chainScan}/${record?.dst_transaction}`} onCopy={onCopy}>
                                <Tooltip title="Copy hash">
                                    <img className="w-15" src="../images/copy-ref.svg" />
                                </Tooltip>
                            </CopyToClipboard>
                        )}
                    </div>
                )
            }
        },
        {
            title: <div className="text-center">Status</div>,
            dataIndex: 'status',
            render: (text: any, record: any, index: any) => {

                let color = 'red';
                if (record?.trade_in_status == "DONE") {
                    color = 'green';
                }

                return (
                    <div className="text-info text-center">
                        <Tag color={color}>{record?.trade_in_status}</Tag>
                    </div>
                )
            }
        },
    ];



    let urlScan: any = 'https://polygonscan.com/';
    if (networkActive == 56) {
        urlScan = 'https://bscscan.com/';
    }

    const handleMax = () => {
        let bal = numberDownDecimal(nativeBalance, 5);
        setAmountFrom(bal);

        let amountTo: any = Number(bal * 1);
        let sellFeeUsdt: any = Number(sellFeePercent * amountTo);

        setAmountTo(amountTo);
        setSellFee(sellFeeUsdt);

    };

    return (
        <>
            <div className="from-info">
                <Row gutter={30} className="d-flex-center">
                    <Col xs={24} sm={12}>
                        <Space.Compact style={{ width: '100%' }}>
                            <Input value={amountFrom} readOnly={nativeBalance == 0 ? true : false} onChange={handleChangeAmount} className="w-full amount-input" placeholder="Ex: 1000" />
                            <Button size="large" onClick={handleMax} className="btn-max w-fitcontent" type="primary">Max</Button>
                        </Space.Compact>
                        <div className="text-gray-light mb-0">
                            <small>Min Sell: <span className="weight-600 text-white">{new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(setting?.min_trade)}</span></small>
                        </div>
                    </Col>
                    <Col xs={24} sm={12}>
                        <div className="text-right mt-15-xs">
                            <Select
                                disabled
                                className="select-trade"
                                placeholder="Select..."
                                value={tokenFrom?.symbol}
                                style={{ width: 150 }}
                                onChange={handleChangeFrom}
                                options={[
                                    { value: 'HLUSD', label: <div className="d-flex-left gap-5"><img width={20} src="../images/heLa-logo.png" /> HLUSD</div> }
                                ]}
                            />
                            <div className="text-white mb-0"><small>Balance: <span className="weight-600">{numberDownDecimal(nativeBalance, 5)}</span></small></div>

                        </div>
                    </Col>
                </Row>
            </div>
            <div className="text-center trade-arrow">
                <img width={50} src="../images/trade-arrow.png" />
            </div>
            <div className="from-info">
                <Row gutter={30} className="d-flex-center">
                    <Col xs={24} sm={12}>
                        <Space.Compact style={{ width: '100%' }}>
                            <Input readOnly value={(numberDownDecimal(amountTo - sellFee, 5))} className="w-full amount-input" placeholder="Ex: 1000" />
                        </Space.Compact>
                    </Col>
                    <Col xs={24} sm={12}>
                        <div className="text-right">
                            <div className="d-flex-right gap-10">
                                <Select
                                    className="select-trade"
                                    placeholder="Select..."
                                    value={tokenTo?.token}
                                    style={{ width: 150 }}
                                    onChange={handleChangeTo}

                                >
                                    {tokenList.length > 0 && tokenList.map((item: any, key: any) => {

                                        let img = '';
                                        if (item?.symbol.toLowerCase() == 'usdt') {
                                            img = 'usdt.svg';
                                        }
                                        if (item?.symbol.toLowerCase() == 'usdc') {
                                            img = 'usdc.svg';
                                        }

                                        return (
                                            <Select.Option key={`tok-${key}`} id={"id_" + `${item?.token}`} data-item={JSON.stringify(item)} value={item?.token}><div className="d-flex-left gap-5"><img width={20} src={`../images/${img}`} /> {item?.symbol.toUpperCase()}</div></Select.Option>
                                        )

                                    })}
                                </Select>
                                <Select
                                    className="select-trade"
                                    placeholder="Choose network..."
                                    value={networkActive}
                                    style={{ width: 150 }}
                                    onChange={handleChangeNetwork}
                                    options={[
                                        { value: 56, label: <div className="d-flex-left gap-5"><img width={20} src="../images/chain-logo/bnb.svg" /> BSC Chain</div> },
                                        { value: 137, label: <div className="d-flex-left gap-5"><img width={20} src="../images/polygon.svg" /> Polygon</div> }
                                    ]}
                                />
                            </div>

                            {/* <p className="text-white mb-0">Balance: <span className="weight-600">0</span></p> */}
                        </div>
                    </Col>
                </Row>
            </div>


            <div className="from-info d-flex-center pool-balance mt-15">
                <div className="text-white">
                    <span className="text-green"><BankOutlined /></span> Pool Balance <span className="text-green"><a className="text-green" target="_blank" href={`${urlScan}/address/${addressPoolTo}`}>({addressPoolTo ? `${addressPoolTo.substring(0, 4)}...${addressPoolTo.substring(addressPoolTo.length - 4)}` : "---"})</a></span>
                </div>
                <div className="text-white d-flex-left gap-5">
                    <span className="text-green weight-600">{new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(poolBalance)}</span> {tokenTo?.symbol}
                </div>
            </div>
            <div className="from-info mt-15">
                <div className="text-white">
                    <span className="text-green mr-5"><ExclamationCircleFilled /></span> <span className="weight-500">Reminder</span> <small className="text-gray-light">(<i>When selling HLUSD, please claim your stablecoin in the designated chain under the Claims tab</i>)</small>
                </div>
                <div className="d-flex-center mt-10">
                    <span className="weight-400 text-white">Price</span>
                    <span className="weight-600 text-white">1 HLUSD = {new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(price)} {tokenTo?.symbol}</span>
                </div>
                <div className="d-flex-center mt-10">
                    <span className="weight-400 text-white">Service Fee ({sellFeePercent * 100}% OTC fee)</span>
                    <span className="weight-600 text-white">~ {numberDownDecimal(sellFee, 5)} {tokenTo?.symbol}</span>
                </div>

                <div className="d-flex-center mt-10">
                    <span className="weight-400 text-white">Estimate Amount</span>
                    <span className="weight-600 text-white">~ {numberDownDecimal(amountTo - sellFee, 5)} {tokenTo?.symbol}</span>
                </div>
            </div>
            <div className="text-center mt-15">
                <a onClick={handleShowBuyHistory}>Transaction History</a>
            </div>
            <div className="text-center mt-15">
                {address ? (
                    <>
                        {chainId == 8668 ? (
                            <>
                                <Button size="large" onClick={showBuyPopup} disabled={!amountFrom || amountFrom <= 0 || amountFrom > nativeBalance || amountFrom > poolBalance || amountFrom < Number(setting?.min_trade) || loading} className="btn-green full-width" htmlType="button">Sell HLUSD {loading && <Spin indicator={antIcon} />}</Button>
                            </>
                        ) : (
                            <>
                                <Alert className="mt-15 mb-15" type="error" message={<>Wrong network, please switch to HeLa. </>}></Alert>
                            </>
                        )}

                    </>
                ) : (
                    <WagmiConnect />
                )}

            </div>

            <Modal footer={false} className="modal-cus" title={<><span className="text-white weight-500 font-20">Sell HLUSD History</span></>} open={modalBuyHistory} onOk={() => setModalBuyHistory(false)} onCancel={() => setModalBuyHistory(false)}>

                <div className="table-cus mt-15">
                    {loadingData ? (
                        <>
                            <div className="text-center"><Spin size="large" /></div>
                        </>
                    ) : (
                        <>
                            <Table scroll={{ y: 500 }} className="table-order" pagination={false} columns={columns} dataSource={orders} />
                        </>
                    )}

                </div>
            </Modal>
            <Modal footer={false} className="modal-cus p-50" title={<div className="text-white font-18 text-center">Requesting to Sell</div>} open={modalBuyConfirm} onOk={onCancelBuy} onCancel={onCancelBuy}>
                <div className="modal-comfirm-buy">
                    <p className="text-gray-light mt-0 text-center">Confirm payment information</p>
                    <div className="d-flex-center mt-10">
                        <span className="weight-400 text-white">Amount</span>
                        <span className="weight-600 text-white">{new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(amountTo)} HLUSD</span>
                    </div>
                    <div className="d-flex-center mt-10">
                        <span className="weight-400 text-white">Service fee</span>
                        <span className="weight-600 text-white">{new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(sellFee)} HLUSD</span>
                    </div>
                    <div className="d-flex-center mt-10">
                        <span className="weight-400 text-white">Total receive</span>
                        <span className="weight-600 text-white">{new Intl.NumberFormat("ja-JP", { maximumFractionDigits: 5 }).format(amountTo - sellFee)} {tokenTo?.symbol}</span>
                    </div>
                    <div className="d-flex-center mt-15 gap-10">
                        <Button size="large" onClick={handleConfirmBuy} disabled={amountTo <= 0 || amountTo > nativeBalance || loadingConfirm} className="btn-green full-width" htmlType="button">Confirm {loadingConfirm && <Spin indicator={antIcon} />}</Button>
                        <Button size="large" onClick={() => window.location.reload()} className="btn-dark full-width" htmlType="button">Cancel</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default SellTrade;
