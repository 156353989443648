import React, { useState, useRef, useEffect } from "react";
import { Col, message, Tabs, Steps, Form, Button, Input, Spin, Row, Alert, Modal, Radio, Space, Table, Pagination } from "antd";

import { AbiItem } from "web3-utils";
import { notiError, notiSuccess } from '../../../utils/notication';
import abiTokenDefault from '../../../config/abi/abiTokenDefault.json'
import abiStake from '../../../config/abi/abiStake.json'
import StakeHelaPoolAbi from '../../../config/abi/StakeHelaPoolAbi.json'
import { LoadingOutlined } from "@ant-design/icons";
import { convertToWei } from "../../../utils/convertNumber";
import Checkpoints from "./checkpoint";
import { CHAINNAMEBYID } from "../../../constants";
import { BaseApi } from "../../../config/api/BaseApi";
import UserInfo from "./userInfo";
import networkChange from "../../../utils/networkChange";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import Web3 from "web3";
import abiClaim from '../../../config/abi/abiClaim.json'
import WagmiConnect from "../../../component/ConnectWallet/wagmi-connect";
import CopyToClipboard from "react-copy-to-clipboard";
import { saveAs } from 'file-saver';
import { AdminApi } from "../../../config/api/AdminApi";
import EBlacklistFeature from "../../../constants/blacklist";
import moment from "moment";

const UINT256_MAX = "115792089237316195423570985008687907853269984665640564039457";

declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#fff', marginLeft: '5px' }} spin />;


const Staking = (prop: any) => {

    const { campaign, configApy, pool } = prop;
    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState()
    const chainId: any = selectedNetworkId;

    let poolChainId: any = localStorage.getItem('poolChainId') ? localStorage.getItem('poolChainId') : chainId;
    let chainName = CHAINNAMEBYID[poolChainId];

    let web3: any

    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    let baseUrl: any = process.env.REACT_APP_BASE_URL;
    let nowDate = new Date(Date.now());
    let startTime = new Date(campaign?.start_time);
    let endTime = new Date(campaign?.end_time);
    const [open, setOpen] = useState(false);
    const [amountApprove, setAmountApprove]: any = useState(0);
    const [isOpenNotifyModal, setIsOpenNotifyModal] = useState(false);
    const [usdtBalance, setUsdtBalance]: any = useState(0);
    const [usdcBalance, setUsdcBalance]: any = useState(0);
    const [nativeBalance, setNativeBalance]: any = useState(0);
    const [stakingAmount, setStakingAmount]: any = useState(0);
    const [loadingStake, setLoadingStake]: any = useState(false);
    const [isConfirmStake, setIsConfirmStake]: any = useState(false);

    const [loadingApprove, setLoadingApprove]: any = useState(false);
    const [isApprove, setIsApprove]: any = useState(false);
    const [stakedInfo, setStakedInfo]: any = useState();
    const [tokenStake, setTokenStake]: any = useState('');


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalSucees, setIsModalSucees] = useState(false);
    const [isBlacklisted, setIsBlacklisted] = useState(false);
    const [userClaimed, setUserClaimed]: any = useState(false);
    const [isModalRef, setIsModalRef] = useState(false);

    const [claimInfo, setClaimInfo]: any = useState(null);
    const [refCode, setRefCode]: any = useState(null);

    const [current, setCurrent] = useState<any>(0);

    const [param, setParam]: any = useState({
        page: 1,
        size: 10
    });

    const getUserStakeInfos = async () => {
        await BaseApi.getUserStakeInfos(address, campaign?.id).then((res) => {

            if (res.status == 200 && res.data && res.data.data) {
                setStakedInfo(res.data.data)
            }
        }).catch((err) => {
            console.log('ClaimInfo', err);
        });
    }

    const getUserClaimInfo = async (address: any) => {
        await BaseApi.getUserClaimInfo(address).then((res) => {
            if (res.status == 200 && res.data && res.data.data) {
                // if(res.data.data.claim_chain_id == chainId){
                setClaimInfo(res.data.data);
                // }

            } else {
                setClaimInfo(null)
            }
        }).catch((err) => {
            console.log('ClaimInfo', err);
        });
    }

    const getRefCode = async (address: any) => {
        await BaseApi.getRefCode(address).then((res) => {
            if (res.status == 200 && res.data && res.data.data) {
                setRefCode(res.data.data);
            } else {
                setRefCode(null)
            }
        }).catch((err) => {
            console.log('ClaimInfo', err);
        });
    }



    let claimContract: any;
    if (claimInfo && claimInfo?.claim_pool) {
        claimContract = new web3.eth.Contract(
            abiClaim as unknown as AbiItem,
            claimInfo?.claim_pool
        );
    }

    const checkUserClaimed = async () => {
        try {

            await claimContract.methods.userClaimed(address)
                .call()
                .then((res: any) => {
                    setUserClaimed(res);
                    console.log('checkUserClaimed', res)
                })
                .catch((errAmountApprove: any) => {
                    console.log('err checkUserClaimed', errAmountApprove)
                })
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        if (address && claimContract) {
            checkUserClaimed();
        }
    }, [address, claimContract])

    useEffect(() => {
        if (address && poolChainId && Number(poolChainId) !== Number(pool?.chain_id)) {
            networkChange(pool?.chain_id);
        }
    }, [poolChainId, address, pool]);

    useEffect(() => {

        if (address) {
            getUserStakeInfos();
        }

    }, [address, param, poolChainId, campaign]);

    useEffect(() => {
        if (address) {
            getUserClaimInfo(address);
        }

    }, [address]);


    const timeLockCheck = () => {
        var holderCheckTime: any = localStorage.getItem('amount_timing');

        if (holderCheckTime) {
            var hours = 2;
            var now: any = new Date().getTime();

            if (now - holderCheckTime > hours * 60 * 60 * 1000) {
                localStorage.removeItem('amount');
                localStorage.removeItem('amount_timing');
                localStorage.removeItem('step');
            }
        }
    }

    useEffect(() => {
        timeLockCheck();
    }, [address]);



    let usdtAddress = pool?.usdt_address;
    let usdcAddress = pool?.usdc_address;
    let nativeAddress = pool?.native_address;
    let stakeAddress = pool?.address;

    let contractUsdtTokenStake: any;
    if (usdtAddress) {
        contractUsdtTokenStake = new web3.eth.Contract(
            abiTokenDefault as unknown as AbiItem,
            usdtAddress
        );
    }

    let contractUsdcTokenStake: any;
    if (usdcAddress) {
        contractUsdcTokenStake = new web3.eth.Contract(
            abiTokenDefault as unknown as AbiItem,
            usdcAddress
        );
    }

    let stakeContract: any = null;
    if (stakeAddress) {
        if (campaign?.type == "HELA") {
            stakeContract = new web3.eth.Contract(
                abiStake as unknown as AbiItem,
                stakeAddress
            );
        } else {
            stakeContract = new web3.eth.Contract(
                StakeHelaPoolAbi as unknown as AbiItem,
                stakeAddress
            );
        }

    }

    const getAmountApprove = async () => {
        try {
            let contractTokenApprove = tokenStake == 'USDT' ? contractUsdtTokenStake : contractUsdcTokenStake;
            let tokenDecimal = tokenStake == 'USDT' ? pool?.usdt_decimal : pool?.usdt_decimal;
            await contractTokenApprove.methods.allowance(address, stakeAddress)
                .call()
                .then((resAmountApprove: any) => {

                    setAmountApprove(Number(resAmountApprove) / (1 * 10 ** Number(tokenDecimal)))
                })
                .catch((errAmountApprove: any) => {
                    console.log('errAmountApprove', errAmountApprove)
                })
        } catch (error) {
            console.log('error', error)
        }
    }

    const getBalanceTokenStake = async (address: any) => {
        try {
            const balanMainToken = await web3.eth.getBalance(address);
            setNativeBalance(Number(balanMainToken) / 1e18);

            const handleError = (errorMsg: boolean, token: string) => {
                if (pool?.chain_id != chainId && !errorMsg) {
                    notiError("Wrong network, Please switch to the correct network");
                    return true;
                } else {
                    if (!errorMsg) {
                        notiError(`Unable to retrieve your ${token} balance. Please try refreshing the page.`);
                    }
                    return errorMsg;
                }
            };

            let errorMsg = false;

            await contractUsdtTokenStake.methods.balanceOf(address).call()
                .then((res: any) => {
                    setUsdtBalance(Number(res) / (1 * 10 ** Number(pool?.usdt_decimal)));
                })
                .catch((err: any) => {
                    errorMsg = handleError(errorMsg, "USDT");
                });

            await contractUsdcTokenStake.methods.balanceOf(address).call()
                .then((res: any) => {
                    setUsdcBalance(Number(res) / (1 * 10 ** Number(pool?.usdc_decimal)));
                })
                .catch((err: any) => {
                    handleError(errorMsg, "USDC");
                });

        } catch (error) {
            notiError("Unable to retrieve your balance. Please try refreshing the page.");
        }
    };

    useEffect(() => {
        if (address && contractUsdtTokenStake) {
            getAmountApprove();
        }
    }, [address, contractUsdtTokenStake, isApprove]);

    useEffect(() => {
        if (chainId && address) {
            getBalanceTokenStake(address);
        }
    }, [address]);

    useEffect(() => {
        if (address) {
            getRefCode(address);
        }
    }, [address]);

    useEffect(() => {
        if (tokenStake == "") {
            if (usdtBalance > usdcBalance) {
                setTokenStake('USDT');
            } else if (usdcBalance > usdtBalance) {
                setTokenStake('USDC');
            }
            if (campaign?.type == "HELA" && nativeBalance > 0 && nativeBalance > usdtBalance && nativeBalance > usdcBalance) {
                setTokenStake('HLUSD');
            }
        }

    }, [usdtBalance, usdcBalance, nativeAddress]);

    // useEffect(() => {
    //     if ((!address || balanceTokenStake == 0 || balanceMainToken == 0) && localStorage.getItem('step')) {
    //         localStorage.removeItem('step');
    //         window.location.reload();
    //     }
    // }, [address, balanceTokenStake, balanceMainToken, current]);


    const steps = [
        {
            title: 'Checkpoints',
            content: <><Checkpoints campaign={campaign} chainName={chainName} pool={pool} usdtBalance={usdtBalance} usdcBalance={usdcBalance} nativeBalance={nativeBalance} /></>,
        },

    ];

    const handleApprove = async () => {
        try {

            let contractTokenApprove = tokenStake == 'USDT' ? contractUsdtTokenStake : contractUsdcTokenStake;

            setLoadingApprove(true)
            const gasPrice = await web3.eth.getGasPrice();
            await contractTokenApprove.methods.approve(
                stakeAddress,
                UINT256_MAX
            )
                .send({
                    from: address,
                    gasPrice: web3.utils.toHex(String(gasPrice))
                })
                .then((resApprove: any) => {

                    const interval = setInterval(function () {
                        web3.eth.getTransactionReceipt(
                            resApprove?.transactionHash,
                            function (err: any, rec: any) {
                                if (rec) {
                                    setLoadingApprove(false)
                                    setIsApprove(true)
                                    notiSuccess('Approve successfully', 3)
                                    clearInterval(interval);
                                }
                                if (err) {
                                    console.log("err", err);
                                    setLoadingApprove(false)
                                    notiError('Error approve', 3)
                                }
                            }
                        )
                    }, 1000);
                })
                .catch((errAprove: any) => {
                    console.log('errAprove', errAprove)
                    notiError(errAprove?.message || 'Unknown error occurred', 4)
                    setLoadingApprove(false)
                })
        } catch (error: any) {
            console.log('error', error)
            setLoadingApprove(false)
        }
    }

    let tokenAmountCheck: any = tokenStake == 'USDT' ? usdtBalance : usdcBalance;
    if (tokenStake == "HLUSD") {
        tokenAmountCheck = nativeBalance;
    }

    const handleStaking = async (values: any) => {
        try {
            setLoadingStake(true)

            let tokenAddress: any = tokenStake == 'USDT' ? usdtAddress : usdcAddress;
            if (tokenStake == "HLUSD") {
                tokenAddress = pool?.native_address;
            }

            if (stakeContract === null) {
                notiError('Contract stake invalid', 4)
                setLoadingStake(false)
                return
            }

            if (values === 0) {
                notiError('Amount must be greater than zero', 4)
                setLoadingStake(false)
                return
            }
            if (values > tokenAmountCheck) {
                notiError('Amount stake invalid', 4)
                setLoadingStake(false)
                return
            }
            if (tokenStake == '') {
                notiError('Token stake invalid', 4)
                setLoadingStake(false)
                return
            }

            let tokenDecimal = tokenStake == 'USDT' ? pool?.usdt_decimal : pool?.usdt_decimal;
            if (tokenStake == "HLUSD") {
                tokenDecimal = pool?.native_decimal;
            }

            let valueStakeConverted = convertToWei(values, Number(tokenDecimal));



            const gasPrice = await web3.eth.getGasPrice();

            let gasEstimate = 0;

            if (campaign?.type == "HELA" && tokenStake == "HLUSD") {
                console.log('type--------', tokenStake);
                gasEstimate = await stakeContract.methods
                    .deposit(
                        tokenAddress,
                        valueStakeConverted
                    )
                    .estimateGas({
                        from: address,
                        value: valueStakeConverted,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });
            } else {
                console.log('type--------', tokenStake);
                gasEstimate = await stakeContract.methods
                    .deposit(
                        tokenAddress,
                        valueStakeConverted
                    )
                    .estimateGas({
                        from: address,
                        // value: valueStakeConverted,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });
            }

            if (campaign?.type == "HELA" && tokenStake == "HLUSD") {
                await stakeContract.methods.deposit(tokenAddress, valueStakeConverted)
                    .send({
                        from: address,
                        value: valueStakeConverted,
                        gas: gasEstimate,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    })
                    .then(async (resStake: any) => {

                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resStake?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        setIsModalSucees(true);

                                        clearInterval(interval);
                                        setTimeout(() => {
                                            getBalanceTokenStake(address)
                                            setLoadingStake(false);
                                            window.location.reload();
                                        }, 3500);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingStake(false)
                                        notiError('Error Staking', 3)
                                    }
                                }
                            )
                        }, 1000);
                    })
                    .catch((errStake: any) => {
                        console.log('errStake', errStake)
                        notiError(errStake?.message || errStake, 3)
                        setLoadingStake(false)
                    })

            } else {
                await stakeContract.methods.deposit(tokenAddress, valueStakeConverted)
                    .send({
                        from: address,
                        gas: gasEstimate,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    })
                    .then(async (resStake: any) => {

                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resStake?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        setIsModalSucees(true);

                                        clearInterval(interval);
                                        setTimeout(() => {
                                            getBalanceTokenStake(address)
                                            setLoadingStake(false);
                                            window.location.reload();
                                        }, 3500);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingStake(false)
                                        notiError('Error Staking', 3)
                                    }
                                }
                            )
                        }, 1000);
                    })
                    .catch((errStake: any) => {
                        console.log('errStake', errStake)
                        notiError(errStake?.message || errStake, 3)
                        setLoadingStake(false)
                    })
            }



        } catch (error: any) {
            console.log('error', error?.message)
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoadingStake(false)
        }
    };

    const showModalStake = () => {
        setIsModalOpen(true);
    }
    const showModalRef = () => {

        setIsModalRef(true);
    }
    const handleOk = () => {
        setIsModalOpen(false);
        setIsModalRef(false);
    };

    const handleCancel = () => {
        setStakingAmount(0);
        setIsModalOpen(false);
        setIsModalRef(false);
    };
    const onClose = () => {
        setOpen(false);
    };
    const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            setStakingAmount(inputValue);
        }
    };

    const onChangeTokenStake = (e: any) => {
        setTokenStake(e.target.value);
    };
    const handleMax = () => {
        if (campaign?.type == "HELA" && tokenStake == "HLUSD") {
            setStakingAmount(nativeBalance);
        } else {
            setStakingAmount(tokenStake == 'USDT' ? usdtBalance : usdcBalance);
        }

    };



    const onCopy = () => {
        notiSuccess('Copied', 3)
    }
    const confirmStake = () => {
        setIsConfirmStake(true);
        setIsModalOpen(false);
    }
    let refStr = `${baseUrl}?pts=${refCode?.code}`;

    return (
        <>


            <div style={{ marginTop: 24 }} className="stake-block">
                <Row gutter={30}>
                    <Col xs={24} sm={24}>
                        {/* <Steps current={current} items={items} className="step-cus"/> */}
                        {chainId && pool?.chain_id != chainId && (
                            <Alert className="mt-15" type="error" message={<>Wrong network, Please switch to {chainName} </>}></Alert>
                        )}

                        <div className="step-content">{steps[current]?.content}</div>
                        <div className="text-center" style={{ marginTop: 24 }}>


                            {address ? (
                                <>
                                    {nowDate > startTime && endTime > nowDate && (
                                        <>
                                            {pool?.amount > 0 && pool?.amount >= campaign?.pool_cap ? (
                                                <>
                                                    <Button size="large" className="btn-green" disabled>
                                                        Filled
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button size="large" className="btn-green" disabled={!address || (campaign?.type != "HELA" && (usdtBalance == 0 && usdcBalance == 0)) || (campaign?.type == "HELA" && nativeBalance == 0)} onClick={showModalStake}>
                                                        Stake now
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {startTime < nowDate && endTime < nowDate && (
                                        <div className="text-center gap-10">
                                            <span className="text-gray-light">ENDED</span>
                                        </div>
                                    )}

                                </>
                            ) : (
                                <>
                                    <WagmiConnect />
                                </>
                            )}



                        </div>
                    </Col>
                    <Col xs={24} sm={24}>
                        <div className="mt-20">
                            <UserInfo campaign={campaign} userClaimed={userClaimed} claimInfo={claimInfo} stakedInfo={stakedInfo} pool={pool} />
                        </div>
                    </Col>
                </Row>

                {/* <div className="referral-block">
                    <div className="referral-title">Invitation Program</div>
                    <div className="referral-content">
                        <Row gutter={30}>
                            <Col xs={24} sm={12}>
                                <div className="text-gray-light text-center">Friends Invited</div>
                                <div className="text-white font-20 mt-10 text-center weight-500">{refCode?.total_users ? refCode?.total_users : 0}</div>
                            </Col>
                            <Col xs={24} sm={12}>
                                <div className="text-gray-light text-center">Your Points</div>
                                <div className="text-white font-20 mt-10 text-center weight-500">{refCode?.points ? refCode?.points : 0}</div>
                            </Col>
                        </Row>
                        <div className="d-flex-center mt-15">
                            <div className="text-gray-light">
                                For each stable coin your friends stake, you will receive x3 points. Friends using your invitation code will also get x2 points on the first staking day. 
                            </div>
                        </div>
                        <div className="d-flex-center mt-15">
                            <div className="text-green">
                                Want to top leaderboard with points? Create your Invitation code and invite friends to earn extra points.
                            </div>
                            <div className="">
                                <button className="btn-green" onClick={showModalRef}>Invite</button>
                            </div>
                        </div>
                    </div>
                    <p className="text-gray-light">Invitation code gives boosted point in 10 days, after that, points will return to normal calculation.</p>
                </div> */}

                <div className="leaderdoard-ref-info referral-block">
                    <Row gutter={30}>
                        <Col xs={24} sm={12}>
                            <div className="referral-title">Invitation Program</div>
                            <Row gutter={30} className="mt-30">
                                <Col xs={24} sm={12}>
                                    <div className="ref-info">
                                        <div className="num font-40 mt-15">{refCode?.total_users ? refCode?.total_users : 0}</div>
                                        <div className="desc">Invited Friends</div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <div className="ref-info">
                                        <div className="num font-40 mt-15">{refCode?.points ? refCode?.points : 0}</div>
                                        <div className="desc">Your Points</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={12}>
                            <div className="leaderdoard-ref">
                                <p className="text-white mt-0">When friends stake using your Invitation code, you'll receive x3 points, friends receive x2 points for the next 10 days.</p>

                                <p className="text-green mt-0 mb-0">Want to top leaderboard with points?</p>
                                <p className="text-white mt-0">Create your Invitation code & Invite now to earn extra points.</p>

                                <div className="">
                                    <div className="share-info">

                                        {address ? (
                                            <div className="border-ref d-flex-mid gap-10">
                                                <CopyToClipboard onCopy={onCopy} text={refStr}>
                                                    <div className="text-white text-center font-16">{refStr ? `${refStr.substring(0, 13)}...${refStr.substring(refStr.length - 8)}` : "---"}</div>
                                                </CopyToClipboard>

                                                <div className="d-flex-mid gap-10">
                                                    <CopyToClipboard onCopy={onCopy} text={refStr}>
                                                        <img className="w-15" src="../images/copy-ref.svg" />
                                                    </CopyToClipboard>
                                                    <a className="twitter-share-button" target="_blank"
                                                        href={`https://x.com/intent/post?hashtags=HeLaLabs,HeLaEcosystem,StableHodl,StakingRewards&text=I've earned stable coin and points everyday on +%40StableHodl, powered by @HeLa_Network. %0A%0AStake for high yields and bonuses with my invite code: ${refCode?.code}%0A%0A&url=${refStr}%0A`}
                                                        data-size="small">
                                                        <img className="w-20" src="../images/x.svg" />
                                                    </a>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <WagmiConnect open={open} onClose={onClose} />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>


            <Modal footer={false} className="modal-cus" title={<><span className="text-white weight-500 font-20">Stake in pool</span></>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="text-center">
                    <p className="mb-10 text-center weight-400 text-gray-light">Select token stake</p>
                    <Radio.Group onChange={onChangeTokenStake} value={tokenStake}>
                        <Space direction="horizontal">
                            {campaign?.type == 'HELA' && (
                                <Radio disabled={nativeBalance == 0} value="HLUSD">HLUSD</Radio>
                            )}
                            {campaign?.type != 'HELA' && (
                                <>
                                    <Radio disabled={usdtBalance == 0} value="USDT">{campaign?.type == 'HELA' ? 'hUSDT' : 'USDT'}</Radio>
                                    <Radio disabled={usdcBalance == 0} value="USDC">{campaign?.type == 'HELA' ? 'hUSDC' : 'USDC'}</Radio>
                                </>
                            )}
                        </Space>
                    </Radio.Group>
                </div>
                <div className="staking-block mt-10">
                    <p className="d-flex-center mt-0 border-bot">
                        <span className="text-gray-light">Balance: </span>
                        <span className="ml-5 weight-500 text-white">

                            {tokenStake == 'USDT' && (
                                <>{new Intl.NumberFormat("ja-JP").format(usdtBalance)}</>
                            )}
                            {tokenStake == 'USDC' && (
                                <>{new Intl.NumberFormat("ja-JP").format(usdcBalance)}</>
                            )}
                            {tokenStake == 'HLUSD' && (
                                <>{new Intl.NumberFormat("ja-JP").format(nativeBalance)}</>
                            )}
                            {' '}

                            {campaign?.type == 'HELA' && tokenStake == 'USDT' && (<span className="text-gray-light">hUSDT</span>)}
                            {campaign?.type == 'HELA' && tokenStake == 'USDC' && (<span className="text-gray-light">hUSDC</span>)}
                            {campaign?.type == 'HELA' && tokenStake == 'HLUSD' && (<span className="text-gray-light">HLUSD</span>)}
                            {campaign?.type != 'HELA' || campaign?.type == '' && (<span className="text-gray-light">{tokenStake}</span>)}
                        </span>
                    </p>
                    <div className="input-stake">
                        <Space.Compact style={{ width: '100%' }}>
                            <Input type="number" value={stakingAmount} size="large" onChange={handleChangeAmount} placeholder="Ex:1000" />
                            <Button size="large" onClick={handleMax} className="btn-green" type="primary">Max</Button>
                        </Space.Compact>
                    </div>
                    {stakingAmount > tokenAmountCheck ? (
                        <span className="error mt-10">Amount staking invalid</span>
                    ) : (
                        <></>
                    )}

                </div>

                <div className="text-center mt-15">
                    <>

                        {amountApprove == 0 && tokenStake != "HLUSD" ? (
                            <>
                                <Button size="large" className="btn-green full-width" disabled={!address || (usdtBalance == 0 && usdcBalance == 0) || nativeBalance == 0 || stakingAmount <= 0 || stakingAmount > tokenAmountCheck || loadingApprove} htmlType="button" onClick={handleApprove}>

                                    {loadingApprove ? (
                                        <>
                                            Approving {loadingApprove && <Spin indicator={antIcon} />}
                                        </>
                                    ) : (
                                        <>
                                            Approve
                                        </>
                                    )}
                                </Button>
                            </>
                        ) : (
                            <>

                                <Button size="large" className="btn-green full-width" disabled={!address || nativeBalance == 0 || stakingAmount <= 0 || stakingAmount > tokenAmountCheck || loadingStake} htmlType="button" onClick={confirmStake}>
                                    Stake
                                </Button>
                                {/* <Button size="large" className="btn-green full-width" disabled={!address || nativeBalance == 0 || stakingAmount <= 0 || stakingAmount > tokenAmountCheck || loadingStake} htmlType="button" onClick={()=> handleStaking(stakingAmount)}>
                                    {loadingStake ? (
                                        <>
                                            Staking {loadingStake && <Spin indicator={antIcon} />}
                                        </>
                                    ):(
                                        <>
                                            Stake
                                        </>
                                    )}
                                    
                                </Button> */}
                            </>
                        )}
                        <Button className="btn-dark full-width mt-15" size="large" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </>
                </div>
            </Modal>
            <Modal footer={false} className="modal-cus" title={<><span className="text-white weight-500 font-20"></span></>} open={isModalSucees} onOk={() => window.location.reload} onCancel={() => window.location.reload}>
                <div className="text-center">
                    <img src="../images/tick-circle.svg" />
                </div>
                <p className="text-center text-white font-20 mt-0">Congratulations!</p>
                <p className="text-center text-white font-18 mt-10">Staked {stakingAmount} {tokenStake}</p>
                <Button size="large" onClick={() => window.location.reload} htmlType="button" className="btn-green full-width">OK</Button>

            </Modal>
            <Modal footer={false} className="modal-cus" title={<><span className="text-white weight-500 font-20">Your Invitation Code</span></>} open={isModalRef} onOk={handleOk} onCancel={handleCancel}>

                <p className="text-white mt-0">Earn up to 2x boosted points with your friends. Sweet part: you earn points every day!</p>
                <div className="share-info">

                    <div className="share-content d-flex-center">
                        <span className="text-white font-16">{refStr}</span>
                        <CopyToClipboard onCopy={onCopy} text={refStr}>
                            <span className="text-white font-16">Copy</span>
                        </CopyToClipboard>

                    </div>
                </div>
                <div className="d-flex-center mt-20">
                    <span className="text-white weight-500">Share to:</span>
                    <div className="d-flex-end">
                        <a target="_blank" href="https://twitter.com/StableHodl"><img width={20} src="../images/x.svg" /></a>
                        <a target="_blank" href="https://discord.gg/stablehodl"><img width={20} src="../images/dis.svg" /></a>
                        <a target="_blank" href="https://t.me/stablehodl"><img width={20} src="../images/tele.svg" /></a>
                    </div>
                </div>
            </Modal>

            <Modal footer={false} onCancel={() => setIsConfirmStake(false)} onOk={() => setIsConfirmStake(false)} className="modal-cus" title={<></>} open={isConfirmStake}>
                <div className="text-center text-blue font-24 mt-0 border-bottom-10 mb-15">Confirm Stake!</div>
                <p className="text-center text-green font-18">Approve to transfer assets</p>
                <div className="text-center mb-15">

                    <div className="">
                        <Button size="large" className="btn-green" disabled={!address || nativeBalance == 0 || stakingAmount <= 0 || stakingAmount > tokenAmountCheck || loadingStake} htmlType="button" onClick={() => handleStaking(stakingAmount)}>
                            {loadingStake ? (
                                <>
                                    Approving {loadingStake && <Spin indicator={antIcon} />}
                                </>
                            ) : (
                                <>
                                    Approve
                                </>
                            )}

                        </Button>
                    </div>

                </div>
            </Modal>
        </>
    );
};
export default Staking;
