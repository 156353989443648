import { useEffect, useState } from 'react';
import HeaderApp from '../header-app';
import Footer from "../../component/common/Footer";
import ContactFormPage from './contact-info';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import ContactForm from './contact-form';
import { useAccount } from 'wagmi';
import { Modal } from 'antd';
import ContactFormList from './contact-list';
import { AdminApi } from '../../config/api/AdminApi';
import { count } from 'console';
import EBlacklistFeature from '../../constants/blacklist';
import { notiError } from '../../utils/notication';

const FAQs = () => {
    let jwtToken: any = localStorage.getItem('jwt');
    const faqs = [
        {
            question: "What is StableHold?",
            answer: "Please kindly check the docs here: <a href='https://docs.stablehodl.com/' target='_blank'> https://docs.stablehodl.com</a>"
        },
        {
            question: "How to stake and claim yields? Which risks I would encounter?",
            answer: "We have step-by-step guidance, check it out here: <a href='https://docs.stablehodl.com/' target='_blank'> https://docs.stablehodl.com</a>, section \"Platform Operations\"  and section \"Disclaimer\"> \"Risk\""
        },
        {
            question: "I would like to talk to Customer Service person, where can I ask?",
            answer: "Please visit our Discord channel: <a href='https://discord.com/invite/stablehodl/' target='_blank'> https://discord.com/invite/stablehodl</a>"

        }
    ];
    const [pageIndex, setPageIndex] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [contacts, setContacts] = useState<any[]>([]);
    const { address } = useAccount();
    const [expandedIndex, setExpandedIndex] = useState(-1);
    const [outOfData, setOutOfData] = useState(false);
    const [isBlacklisted, setIsBlacklisted] = useState(true);
    const pageSize = 5;

    const loadMoreData = async (pageIndex: number) => {

        if (pageIndex >= totalPage - 1 || totalPage == 1) {
            setOutOfData(true);
            return;
        }

        if (pageIndex < totalPage) {
            setPageIndex(pageIndex);
            try {
                const res = await AdminApi.getContacts(false, pageIndex, pageSize);
                if (res.status === 200 && res.data) {
                    setContacts(() => [...contacts, ...res.data]);
                }
            } catch (error) {
                console.error('Error loading data:', error);
            }
        }
        else {
            setOutOfData(true);
            setPageIndex(pageIndex);
            return;
        }

    };

    const toggleFAQ = (index: number) => {
        setExpandedIndex(expandedIndex === index ? -1 : index);
    };

    const getContacts = async () => {
        try {
            const res = await AdminApi.getContacts(false, pageIndex, pageSize);
            if (res.status === 200 && res.data) {
                setContacts(res.data);
                setOutOfData((pageIndex < totalPage) ? false : true);

                if (totalPage == 1) {
                    setOutOfData(true);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSignIn = async () => {
        const signature = localStorage.getItem('signature');
        const message = `Welcome to StableHodl!`;
        const issuedAt = Math.floor(Date.now() / 1000);
        if (!address || !signature) {
            return;
        }
        try {
            const res = await AdminApi.signIn(address.toLocaleLowerCase(), message, signature, issuedAt);
            if (res.status === 200 && res.data && res.data.data) {
                localStorage.setItem('jwt', res.data.data.jwtToken);
                jwtToken = res.data.data.jwtToken;
                console.log('Sign in success');
                window.location.reload();
            }
        } catch (error) {
            localStorage.removeItem('jwt');
            console.error('Signed in without jwt:');
            return;
        }
    }


    const addContact = async (contact: any) => {
        try {
            if (contact) {
                setContacts([contact, ...contacts]);
            }
        } catch (error) {
            console.error('Error adding contact:', error);
        }
    };

    const getTotal = async () => {
        try {
            const res = await AdminApi.getContacts(true);
            if (res.status === 200 && res.data) {
                setTotalItems(res.data);
                if (res.data > pageSize) {
                    setTotalPage(Math.ceil(res.data / pageSize) ?? 0);
                }
                else {
                    setTotalPage(1);
                    setOutOfData(true);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const checkBlacklist = async () => {
        try {
            if (!address) {
                localStorage.removeItem('jwt');
                return;
            }
            const response = await AdminApi.checkUserBlacklist(address, EBlacklistFeature.Contact);
            if (response.status === 200) {
                if (response.data.length > 0) setIsBlacklisted(true);
                else setIsBlacklisted(false);
            } else if (response.status === 401 && jwtToken) {
                notiError('Your session has expired. Please log in again.', 5);
                localStorage.removeItem('jwt');
            }
        } catch (error) {
            console.error('Error checking blacklist:', error);
        }
    };

    useEffect(() => {
        if (address)
            checkBlacklist();
    }, [address, isBlacklisted]);

    useEffect(() => {
        if (address && !jwtToken)
            handleSignIn();
    }, [jwtToken]);

    useEffect(() => {
        getTotal();
        getContacts();
    }, [totalItems, pageIndex, totalPage]);

    useEffect(() => {
        loadMoreData(pageIndex);
    }, [pageIndex]);

    return (
        <>
            <div className="app-page bg-main">
                <div className="page-content">
                    <HeaderApp />
                    <div className="container">
                        <div className="faqs-page text-white">
                            <div>
                                <h1 className='head-title'>CONTACT</h1>
                                < ContactFormPage />
                                <h2 className='mt-30 faq-title'>FAQs</h2>
                                <div className="faq-container">
                                    {faqs.map((faq, i) => (
                                        <div key={i} className={`faq ${i === 0 ? 'border-top' : ''}`}>
                                            <div className="faq-header" onClick={() => toggleFAQ(i)}>
                                                <div className="faq-question">{faq.question}</div>

                                                {expandedIndex !== i ? <> <PlusOutlined /></> : <><MinusOutlined /></>}
                                            </div>
                                            <div className="faq-answer" hidden={expandedIndex !== i}>
                                                <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                            </div>
                                        </div>
                                    ))}

                                    {
                                        jwtToken && <>
                                            <div className='mt-30 faq-title'>SUBMIT A TICKET</div>
                                            <ContactForm address={address} addContact={(contact: any) => addContact(contact)} isBlacklisted={isBlacklisted} />
                                            <ContactFormList address={address} contacts={contacts} parentId={0} outOfData={outOfData} onClickLoadMore={() => address && loadMoreData(pageIndex + 1)} isBlacklisted={isBlacklisted} />
                                        </>
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default FAQs;