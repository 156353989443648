
const ContactFormPage = () => (
    <div className="contact-form-page-container stake-block">
        <div className="wrapper">
            <div className="note-container">
                <ul>
                    <li className="note">Please connect with your wallet to submit tickets for your questions (no transaction cost) and receive responses.</li>
                    <li className="note">Kindly wait while your tickets are queued for a response.  In your ticket/question please specify the network, Tx, actions (i.e., network = Polygon, Tx = ...., action = claim yield)  that you want to ask about.</li>
                    <li className="note">Important notice:</li>
                    <ul>
                        <li className="sub-note">Never disclose the private key of your wallet to anyone.</li>
                        <li className="sub-note">This page is one of our channels for users to ask questions and receive responses. We also have <a href='https://docs.stablehodl.com/' target='_blank'> document</a> and <a href='https://discord.com/invite/stablehodl/' target='_blank'>Discord channel</a>.</li>
                    </ul>
                </ul>
            </div>
        </div>
    </div>
);

export default ContactFormPage;