/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import ConnectWallet from "../ConnectWallet";
import { Select, Drawer, Input, Modal, Spin, Empty, Menu } from "antd";
import { CaretDownOutlined, SearchOutlined, MonitorOutlined, DeleteOutlined } from "@ant-design/icons";
import type { MenuProps } from 'antd';

import { useWeb3ModalState } from "@web3modal/wagmi/react";


declare const window: Window & typeof globalThis & { ethereum: any };

const SwithChain = () => {

  const { selectedNetworkId } = useWeb3ModalState()
  const chainId: any = selectedNetworkId;

  const { Option } = Select;

  const changeNetwork = async (chainId: any, value: any) => {



    try {
      await window.ethereum.enable();
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${parseInt(chainId).toString(16)}` }],
      });
      localStorage.setItem('poolChainId', chainId);
      window.location.reload();
    } catch (error) {
      //@ts-ignore
      if (error.code === 4902) {

        try {
          if (Number(value.value) === 1) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: "Ethereum Chain Mainnet",
                  chainId: "0x1",
                  rpcUrls: ["https://mainnet.infura.io/v3"],
                  nativeCurrency: {
                    name: "ETH",
                    symbol: "ETH",
                    decimals: 18,
                  },
                },
              ],
            });
          } else if (Number(value.value) === 137) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: 'Polygon Mainnet',
                  // chainId: '0x89',
                  chainId: `0x${Number(137).toString(16)}`,
                  rpcUrls: [
                    "https://polygon-rpc.com",
                    "https://rpc-mainnet.matic.network",
                    "https://rpc-mainnet.maticvigil.com",
                    "https://rpc-mainnet.matic.quiknode.pro",
                    "https://matic-mainnet.chainstacklabs.com",
                    "https://matic-mainnet-full-rpc.bwarelabs.com",
                    "https://matic-mainnet-archive-rpc.bwarelabs.com",
                    "https://poly-rpc.gateway.pokt.network/",
                    "https://rpc.ankr.com/polygon",
                    "https://rpc-mainnet.maticvigil.com/"
                  ],
                  blockExplorerUrls: ["https://polygonscan.com/"],
                  nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18
                  }
                }
              ]
            });
          } else if (Number(value.value) === 56) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: "BNB Chain Mainnet",
                  chainId: "0x38",
                  rpcUrls: ["https://bsc-dataseed.binance.org"],
                  nativeCurrency: {
                    name: "BNB",
                    symbol: "BNB",
                    decimals: 18,
                  },
                },
              ],
            });
          } else if (Number(value.value) === 8668) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: "HeLa Official Runtime",
                  chainId: '0x21dc',
                  rpcUrls: ["https://mainnet-rpc.helachain.com"],
                  nativeCurrency: {
                    name: "HeLa Native Token",
                    symbol: "HLUSD",
                    decimals: 18,
                  },
                },
              ],
            });
          } else if (Number(value.value) === 666888) {

            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: "HeLa Official Runtime Testnet",
                  chainId: '0xa2d08',
                  rpcUrls: ["https://testnet-rpc.helachain.com"],
                  nativeCurrency: {
                    name: "HeLa Native Token",
                    symbol: "HLUSD",
                    decimals: 18,
                  },
                },
              ],
            });
          }
        } catch (addError) {
          console.log("addError", addError);
          // handle "add" error
        }
      }

    }
  };




  return (
    <>
      <div className="select-fiter mr-15">

        {chainId == 666888 || chainId == 8668 || chainId == 56 || chainId == 1 || chainId == 137 || chainId == 42161 ? (
          <>
            <Select
              value={chainId ? chainId.toString() : 0}
              style={{ width: 180 }}
              onChange={changeNetwork}
              suffixIcon={<CaretDownOutlined rev={undefined} />}
            >
              <Option value="8668">
                <img src="" alt="" />{" "}
                <span className="text-chain">HeLa</span>
              </Option>
              {/* <Option value="666888">
                  <img src="" alt="" />{" "}
                  <span className="text-chain">HeLa Testnet</span>
                </Option> */}

              <Option value="56">
                <img src="" alt="" />{" "}
                <span className="text-chain">BNB Chain</span>
              </Option>
              {/* <Option value="42161">
                  <img src="" alt="" />{" "}
                  <span className="text-chain">Arbitrum</span>
                </Option> */}
              <Option value="1">
                <img src="" alt="" />{" "}
                <span className="text-chain">Ethereum</span>
              </Option>
              <Option value="137">
                <img src="" alt="" />{" "}
                <span className="text-chain">Polygon</span>
              </Option>
            </Select>
          </>
        ) : (
          <>
            <button onClick={() => changeNetwork(56, 56)} className="btn-wrong">Wrong network: Switch to BSC</button>
            {/* <Select
                value=""
                style={{ width: 180 }}
                onChange={changeNetwork}
                suffixIcon={<CaretDownOutlined rev={undefined} />}
              >
                <Option value="">
                  <img src="" alt="" />{" "}
                  <span className="text-chain">Wrong network</span>
                </Option>
              </Select> */}
          </>
        )}
      </div>
    </>
  );
};
export default SwithChain;
