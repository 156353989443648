
import { Col, message, Tabs, Steps, Form, Button, Input, Spin, Row, Alert } from "antd";
import Web3 from "web3";
import { LoadingOutlined, CheckOutlined } from "@ant-design/icons";
import { token_network } from "../../../constants";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";

const UINT256_MAX = "115792089237316195423570985008687907853269984665640564039457";

declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;


const Checkpoints = (props: any) => {

    const { campaign, chainName, pool, usdtBalance, usdcBalance, nativeBalance } = props;

    const { address, connector } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState()
    const chainId: any = selectedNetworkId

    let web3: any;
    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    let nativeToken = token_network(pool?.chain_id);

    return (
        <>
            <div className="">
                {address && chainName != '' ? (
                    <h3 className="mt-0 text-center font-20 text-white">Staking on {chainName}</h3>
                ) : (
                    <h3 className="mt-0 text-center font-20 text-white">Please connect your wallet</h3>
                )}
                <p className="text-center font-16 text-gray-light mt-10">The following conditions must be met to proceed:</p>
                <Row gutter={16}>
                    <Col xs={24} sm={8}>
                        <div className="point-info">
                            <p className={`icon ${!address && 'icon-red'}`}><CheckOutlined /></p>
                            <p className="text-white">
                                {address ? (
                                    <>Connected with {connector?.name}</>
                                ) : (
                                    <>Please connect your wallet</>
                                )}

                            </p>
                            <p className="text-gray-light">If not connected, click the "Connect Wallet" button in the top right corner</p>
                        </div>
                    </Col>
                    <Col xs={24} sm={8}>
                        <div className="point-info">
                            <p className={`icon ${usdtBalance == 0 && usdcBalance == 0 && nativeBalance == 0 && 'icon-red'}`}><CheckOutlined /></p>
                            <p className="text-white">Balance available to deposit</p>
                            {campaign?.type != "HELA" && (
                                <>
                                    <p className="text-gray-light">
                                        {campaign?.type == "HELA" ? 'hUSDT' : 'USDT'} Balance: <span className="text-white">{new Intl.NumberFormat("ja-JP").format(usdtBalance)}</span>
                                    </p><p className="text-gray-light">
                                        {campaign?.type == "HELA" ? 'hUSDC' : 'USDC'} Balance: <span className="text-white">{new Intl.NumberFormat("ja-JP").format(usdcBalance)}</span>
                                    </p>
                                </>
                            )}
                            {campaign?.type == "HELA" && (
                                <p className="text-gray-light">
                                    HLUSD Balance: <span className="text-white">{new Intl.NumberFormat("ja-JP").format(nativeBalance)}</span>
                                </p>
                            )}

                        </div>
                    </Col>
                    <Col xs={24} sm={8}>
                        <div className="point-info">
                            <p className={`icon ${nativeBalance == 0 && 'icon-red'}`}><CheckOutlined /></p>
                            <p className="text-white">
                                {address ? (
                                    <>
                                        {nativeToken} available in wallet
                                    </>
                                ) : (
                                    <>Native token available in wallet</>
                                )}

                            </p>
                            <p className="text-gray-light">
                                {address ? (
                                    <>
                                        {nativeToken} is required to pay transaction fees on the {chainName} network.<br></br>
                                    </>
                                ) : (
                                    <>
                                        Native token is required to pay transaction fee.....<br></br>
                                    </>
                                )}

                                {address && (
                                    <>{nativeToken} Balance: <span className="text-white">{new Intl.NumberFormat("ja-JP").format(nativeBalance)}</span></>
                                )}

                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default Checkpoints;
