import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import ContactDetail from './contact-detail';
import { Button, Modal } from 'antd';
import { AdminApi } from '../../config/api/AdminApi';
import EBlacklistFeature from '../../constants/blacklist';
import { notiError } from '../../utils/notication';

const ContactFormList = (prop: any) => {
    const { address, contacts, onClickLoadMore, outOfData, isBlacklisted } = prop;
    const [contactList, setContactList] = useState(contacts);
    let jwtToken: any = localStorage.getItem('jwt');
    const [viewImageUrl, setViewImageUrl] = useState<string | null>(null);

    const [isOpenModal, setIsOpenModal] = useState(false);

    const onViewImage = (imageUrl: string) => {
        setViewImageUrl(imageUrl);
    };

    const updateContact = async (contact: any) => {
        try {
            if (contact) {
                const updatedContacts = contacts.map((contactInfo: any) =>
                    contactInfo.id === contact.id ? contact : contactInfo
                );
                setContactList(updatedContacts);
            }
        } catch (error) {
            console.error('Error updating contact:', error);
        }
    };

    useEffect(() => {
        setContactList(contacts);
    }, [contacts]);


    const serverImage = (imageUrl: string, domain: string = ""): string | undefined => {
        if (!imageUrl) return undefined;
        if (imageUrl.endsWith('.zip')) return "../images/zip.png";
        if (imageUrl.endsWith('.rar')) return "../images/rar.png";
        const baseUrl = domain.includes("http") ? domain : '';
        return `${baseUrl}${imageUrl}`;
    };

    return (
        <div className="contact-form-list-container mb-30">
            {contactList && jwtToken && contactList.map((contact: { id: React.Key | null | undefined; status: any; createdAt: string | number | Date; imageUrl: string | undefined; content: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => (
                <div className="contact" key={contact.id}>
                    <div className="ticket-info">
                        <div className="ticket-id">Ticket: {'#' + contact.id}</div>
                        <div className="flex-center gap-10">
                            <div className={`status ${contact.status === 0 ? 'pending' : 'reply'}`}>
                                {(() => {
                                    switch (contact.status) {
                                        case 0:
                                            return 'Pending';
                                        case 1:
                                            return 'Responded';
                                        case 2:
                                            return 'Closed';
                                        default:
                                            return 'Pending';
                                    }
                                })()}
                            </div>
                            <div className="created-at">
                                {format(new Date(contact.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                            </div>
                        </div>
                    </div>
                    {contact && contact.imageUrl && (
                        <>
                            <img
                                className="viewer mt-10"
                                src={serverImage(contact.imageUrl, process.env.REACT_APP_ADMIN_API_URL)}
                                alt="Click to view"
                                width="55px"
                                height="55px"
                                onClick={() => {
                                    if (contact.imageUrl) {
                                        if (contact.imageUrl.endsWith('.zip') || contact.imageUrl.endsWith('.rar')) {
                                            window.open(process.env.REACT_APP_ADMIN_API_URL + contact.imageUrl, '_blank');
                                        } else {
                                            onViewImage(contact.imageUrl);
                                            setIsOpenModal(true);
                                        }
                                    }
                                }}
                            />
                            <Modal className="modal-cus contact-modal preview-modal" title={<><span className="text-white weight-500 font-20">Preview image</span></>}
                                visible={isOpenModal}
                                footer={null}
                                onCancel={() => setIsOpenModal(false)}
                            >
                                <img
                                    src={viewImageUrl ? serverImage(viewImageUrl, process.env.REACT_APP_ADMIN_API_URL) : ''}
                                    alt="Full view"
                                    style={{ width: '100%' }}
                                />
                            </Modal>
                        </>
                    )}
                    <div className="contact-content">{contact.content}</div>
                    <ContactDetail address={address} contact={contact} updateContact={(contact: any) => updateContact(contact)} isBlacklistBlocked={isBlacklisted} />
                </div>
            ))}
            {!outOfData && (
                <Button size="large" className="btn-load-more btn-green" onClick={onClickLoadMore}>
                    Load more
                </Button>
            )}
        </div>
    );
};

export default ContactFormList;