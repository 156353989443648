/* eslint-disable no-restricted-globals */
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import type { MenuProps } from 'antd';
import { Drawer, Menu, Modal } from "antd";
import queryString from 'query-string';
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import Web3 from "web3";
import SwithChain from "../component/ConnectWallet/SwitchChain";
import WagmiConnect from "../component/ConnectWallet/wagmi-connect";
import { AdminApi } from "../config/api/AdminApi";
import { BaseApi } from "../config/api/BaseApi";
import { notiError, notiSuccess } from "../utils/notication";
import { set } from "lodash-es";

declare const window: Window &
  typeof globalThis & {
    trustwallet: any;
    ethereum: any;
    bitkeep: any;
    okexchain: any;
    safepalProvider: any;
  };

const HeaderApp = () => {

  const { address, connector, isConnected } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState()

  let refCode: any = '';
  let paramUrl: any = queryString.parse(location.search);
  if (paramUrl.pts !== undefined && paramUrl.pts.length > 0) {
    refCode = paramUrl.pts;
    localStorage.setItem('refCode', refCode);
  }

  let signatureMeta: any = localStorage.getItem('signature') ? localStorage.getItem('signature') : null;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { disconnect } = useDisconnect();
  const [isOpenModal, setIsOpenModal] = useState(false);
  let web3: any;
  const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");


  if (connectorId === "io.metamask" || connectorId === "bsc") {
    web3 = new Web3(window.ethereum);
  } else if (connectorId === "com.trustwallet.app") {
    web3 = new Web3(window.trustwallet);
  } else if (connectorId === "com.bitget.web3") {
    web3 = new Web3(window.bitkeep.ethereum);
  } else if (connectorId === "com.okex.wallet") {
    web3 = new Web3(window.okexchain);
  } else {
    web3 = new Web3(window.ethereum);
  }

  let defaut: any = 'stake';
  let path = window.location.pathname;

  if (path == "/claim") {
    defaut = 'claim';
  }
  if (path == "/leaderboard") {
    defaut = 'leaderboard';
  }
  if (path == "/trade") {
    defaut = 'trade';
  }
  if (path == "/profile") {
    defaut = 'profile';
  }
  if (path == "/faqs") {
    defaut = 'faqs';
  }
  if (path == "/") {
    defaut = 'home';
  }


  const [current, setCurrent] = useState(defaut);
  const items: MenuProps['items'] = [
    {
      label: (
        <a href="/">Home</a>
      ),
      key: 'home',
    },
    {
      label: (
        <a href="/stakes">Stake</a>
      ),
      key: 'stake',
    },
    {
      label: (
        <a href="/claim">Claim</a>
      ),
      key: 'claim',
    },
    {
      label: (
        <a href="/leaderboard">Leaderboard</a>
      ),
      key: 'leaderboard',
    },
    {
      label: (
        <a href="/profile">Profile</a>
      ),
      key: 'profile',
    },
    {
      label: (
        <a href="/trade">Trade HLUSD</a>
      ),
      key: 'trade',
    },
    {
      label: (
        <a href="/faqs">Contact</a>
      ),
      key: 'faqs',
    },
    {
      label: (
        <a href="https://docs.stablehodl.com" target="_blank">Docs</a>
      ),
      key: 'docs',
    },
  ];

  const onClickActiveMenu: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const issuedAt = Math.floor(Date.now() / 1000);
  const message = `Welcome to StableHodl!`;

  const getSignMeta = async () => {
    if (signatureMeta !== null) return;
    let _signature;
    try {
      if (address) setIsOpenModal(true);
      const signature: string | null = await web3.eth.personal.sign(message, address).catch((error: any) => {
        handleSignatureRejected();
        if (error.code === 4001) {
          console.log('User rejected the signature request');
        } else {
          console.log('Error signing message:', error);
        }
        setOpen(true);
        return null;
      });

      if (signature) {
        localStorage.setItem('signature', signature);
        _signature = signature;
        if (!refCode && localStorage.getItem('refCode')) {
          refCode = localStorage.getItem('refCode');
        }
        await handleSignIn(signature);
        window.location.reload();
      }
    } catch (error) {
      handleSignatureRejected();
      console.log('Connect wallet failed, ', error);
      notiError('Connect wallet failed, please try again.', 3);
    }

    if (refCode && address && _signature)
      await handleSaveRefCode(address, _signature);
  };

  const handleSaveRefCode = async (address: any, signature: any) => {
    try {
      if (refCode) {
        setupRefcode(address, signature);
      }
    } catch (error) {
      console.log('Failed to set refcode ', error);
      localStorage.removeItem('refCode')
      return;
    }

  }

  const handleSignIn = async (signature: string) => {
    if (!address) {
      return;
    }
    try {
      const res = await AdminApi.signIn(address.toLocaleLowerCase(), message, signature, issuedAt);
      if (res.status === 200 && res.data && res.data.data) {
        localStorage.setItem('jwt', res.data.data.jwtToken);
        console.log('Sign in success');
      }
    } catch (error) {
      localStorage.removeItem('jwt');
      console.error('Signed in without jwt');
      return;
    }
  }

  const handleSignatureRejected = () => {
    setIsOpenModal(false);
    disconnect();
    localStorage.removeItem('signature');
    localStorage.removeItem('jwt');
  };



  const setupRefcode = async (address: any, signature: any) => {
    if (!address && refCode) {
      notiError('Connect your wallet to activate Invitation Code.', 3);
      return;
    }

    let payload: any = {
      "wallet": address,
      "message": message,
      "signature": signature,
      "code": refCode
    }

    if (refCode) {
      const cleanup = () => localStorage.removeItem('refCode');
      await BaseApi.setRefCode(payload).then((res) => {
        if (res.status == 201 && res.data && res.data.data) {
          notiSuccess('Invitation Code is valid!', 3);
          console.log('set ref success');
          cleanup();
          window.location.reload();
        }
      }).catch((err) => {
        console.log('set refcode error', err);
      });
    }

  }

  useEffect(() => {
    if (address && signatureMeta == null) {
      getSignMeta();
    }

  }, [address, signatureMeta]);

  useEffect(() => {
    if (address && window?.ethereum) {
      window.ethereum.on('accountsChanged', function () {
        localStorage.removeItem('signature');
        localStorage.removeItem('jwt');
      });
    }
  }, [address]);

  useEffect(() => {
    if (refCode != '') {
      setupRefcode(address, signatureMeta);
    }

  }, [address, signatureMeta, refCode]);

  return (
    <>
      {isMobile ? (
        <>
          <header className="header">
            <div className="main-header">
              <div className="header-left">
                <div className="icon-bar" onClick={showDrawer}>
                  <Link to="/"><img src="../../../images/logo-short.svg" alt="" /></Link>
                </div>
              </div>
              <div className="select-fiter">
                <WagmiConnect open={open} onClose={onClose} />
              </div>
              <div className="round-connect">
                <div className="icon-bar" onClick={showDrawer}>
                  <img width="40" src="../../../images/bar-m-1.png" alt="" />
                </div>
              </div>
            </div>
          </header>
          <Drawer className="drawer-menu" title="" placement="right" onClose={onClose} open={open}>
            <div className="menu-group">
              <Menu className="header-menu" onClick={onClickActiveMenu} selectedKeys={[current]} mode="vertical" items={items} />
            </div>
          </Drawer>
        </>
      ) : (
        <header className="header">
          <div className="main-header">
            <div className="header-left">
              <div className="box-img">
                <Link to="/"><img src="../../../images/stable-hodl.svg" alt="" /></Link>
              </div>
            </div>
            <div className="header-center">
              <Menu className="header-menu" onClick={onClickActiveMenu} selectedKeys={[current]} mode="horizontal" items={items} />

            </div>
            <div className="header-right">
              {address && (
                <>
                  <SwithChain />
                </>
              )}

              <div className="round-connect">
                <WagmiConnect open={open} onClose={onClose} />
              </div>
            </div>
          </div>
        </header>
      )}
      <Modal footer={false} className="modal-cus" title={<><span className="text-white weight-500 font-20">StableHodl Connecting...</span></>} open={isOpenModal} closable={false}>
        <p className="border-bottom-green"></p>
        <div className="wallet-connect-dialog-container">
          <div className="dialog-content mat-dialog-content">
            <div className="logo-container">
              <img className="logo" src="../../images/info1.svg" height="100" width="100" alt="Logo" />
              <div className="light-streak"></div>
            </div>
            <p className="text-white font-18">
              Please confirm to connect your wallet to StableHodl.
            </p>
          </div>
        </div>
      </Modal>
    </>
  );

};
export default HeaderApp;
