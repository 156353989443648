
import { CheckOutlined, LoadingOutlined, RightOutlined } from "@ant-design/icons";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { Alert, Button, Col, Empty, Input, Modal, Row, Spin, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import UnStakeAbi from '../../../config/abi/UnStakeAbi.json';
import { BaseApi } from "../../../config/api/BaseApi";
import { CHAINNAMEBYID } from "../../../constants";
import CountdownReloadComponent from "../../../utils/countDownReload";
import networkChange from "../../../utils/networkChange";
import { notiError, notiSuccess } from '../../../utils/notication';
import UserInfo from "./userInfo";
import CopyToClipboard from "react-copy-to-clipboard";

declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#fff', marginLeft: '5px' }} spin />;


const Withdraw = (prop: any) => {

    const { configApy, isUnstakeBlocked, isWithdrawBlocked, isClaimBlocked } = prop;
    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState()
    const chainId: any = selectedNetworkId

    let web3: any;
    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }
    let keyTab = localStorage.getItem('keyTab');

    const [param, setParam]: any = useState({
        page: 1,
        size: 100
    });

    const [historys, setHistorys]: any = useState([]);
    const [balanceMain, setBalanceMain]: any = useState(0);
    const [loadingWithdraw, setLoadingWithdraw]: any = useState(false);
    const [claimInfo, setClaimInfo]: any = useState(null);
    const [userClaimed, setUserClaimed]: any = useState(false);
    const [showUnstake, setShowUnstake]: any = useState(false);
    const [amountWithdraw, setAmountWithdraw]: any = useState('');
    const [userRequested, setUserRequested]: any = useState(null);
    const [loadingRetryClaim, setLoadingRetryClaim]: any = useState(false);
    const [loadingKey, setLoadingKey]: any = useState(0);
    const [isModalSucees, setIsModalSucees] = useState(false);
    const [isModalFail, setIsModalFail] = useState(false);
    const [isConfirmUnStake, setIsConfirmUnStake] = useState(false);


    let totalWithdraw: any = claimInfo?.hlusd_amount ? claimInfo?.hlusd_amount : 0;

    let signatureMeta: any = localStorage.getItem('signature');

    const getUserClaimInfo = async () => {
        await BaseApi.getUserClaimInfo(address).then((res) => {
            if (res.status == 200 && res.data && res.data.data) {
                setClaimInfo(res.data.data);
            } else {
                setClaimInfo(null);
            }
        }).catch((err) => {
            console.error('Error fetching ClaimInfo:', err);
        });
    }

    const checkUserHasRequested = async () => {
        await BaseApi.checkUserRequested(address).then((res) => {
            if (res.status == 200 && res.data && res.data.data) {
                // if(res.data.data.claim_chain_id == chainId){
                setUserRequested(res.data.data);
                // }

            } else {
                setUserRequested(null);
            }
        }).catch((err) => {
            console.log('ClaimInfo', err);
        });
    }
    const getHistories = async () => {

        await BaseApi.getUnstakeHistories(param, address).then((res) => {

            if (res.status == 200 && res.data && res.data.data) {

                let datas: any = res.data.data;
                setHistorys(datas);
            } else {
                setHistorys([])
            }
        }).catch((err) => {
            console.log('ClaimInfo', err);
        });
    }

    useEffect(() => {
        if (address && chainId) {
            getUserClaimInfo();
            checkUserHasRequested();
            getHistories();
        }

    }, [address, chainId]);



    useEffect(() => {
        if (keyTab == "withdraw-fund" && configApy && chainId && chainId !== configApy?.unstake_pool_chain) {
            networkChange(configApy?.unstake_pool_chain);
        }
    }, [chainId, configApy]);


    const getNativeBalance = async (address: any) => {
        try {
            let balanMa = await web3.eth.getBalance(address);
            setBalanceMain(Number(balanMa) / 1e18)

        } catch (error) { }
    }

    useEffect(() => {
        if (address && chainId && chainId === claimInfo?.claim_chain_id) {
            getNativeBalance(address);
        }
    }, [address, claimInfo]);

    const handleShowClaim = () => {
        setShowUnstake(true);
    }

    const onOk = () => {
        setShowUnstake(false);
    }
    const onCancel = () => {
        setShowUnstake(false);
    }

    const handleRequestUnstake = async (amountWithdraw: any) => {
        try {
            setLoadingWithdraw(true);



            if (Number(amountWithdraw) === 0 || claimInfo?.hlusd_amount == 0) {
                notiError('Amount unstake invalid', 4)
                setLoadingWithdraw(false)
                return
            }


            let data = {
                "wallet": address,
                "message": "Welcome to StableHodl!",
                "signature": signatureMeta,
                "amount": Number(amountWithdraw)
            }
            await BaseApi.requestUnstake(data)
                .then((res) => {
                    if (res.status === 201) {
                        notiSuccess('Request Unstake Succeeded.', 5);

                        // call api check has requested unstake
                        if (address && chainId) {
                            getUserClaimInfo();
                            checkUserHasRequested();
                            getHistories();
                        }
                        setShowUnstake(false);
                        setLoadingWithdraw(false);

                    } else {
                        notiError(res?.data?.message, 5);
                    }
                }).catch(() => {
                    notiError('Request Unstake  Failed.', 5);
                });

        } catch (error: any) {
            console.log('error', error?.message)
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoadingWithdraw(false)
        }
    };

    const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            setAmountWithdraw(inputValue);
        }
    };

    const handleMax = () => {
        setAmountWithdraw(totalWithdraw);
    };

    const sltAmountWithdraw = (percent: any) => {
        setAmountWithdraw(totalWithdraw * (percent / 100));
    };

    let chainName = configApy ? CHAINNAMEBYID[configApy?.unstake_pool_chain] : 0;



    let nowTime = new Date();

    let endTime = userRequested && userRequested?.claim_time ? new Date(userRequested?.claim_time) : '';

    const handleUnstake = async () => {
        try {
            setLoadingWithdraw(true);

            // if (chainId != 8668) {
            //     notiError('Wrong network.Please select HeLa Mainnet', 4)
            //     setLoadingClaim(false)
            //     return
            // }
            if (!signatureMeta) {
                notiError('Wrong signature', 4)
                setLoadingWithdraw(false)
                return
            }


            // call gen sign
            let data = {
                "wallet": address,
                "message": "Welcome to StableHodl!",
                "signature": signatureMeta
            }

            let rsClaim: any = await BaseApi.signUnstake(data);
            if (rsClaim.status && rsClaim.data.data) {
                let dataClaim = rsClaim.data.data;
                ;

                let amountClaim: any = dataClaim?.claim_amount;

                if (amountClaim <= 0) {
                    notiError('Amount Unstake invalid', 4)
                    setLoadingWithdraw(false);
                    setIsConfirmUnStake(false);
                    return
                }
                if (!dataClaim?.claim_sign) {
                    notiError('Unstake sign invalid', 4)
                    setLoadingWithdraw(false);
                    setIsConfirmUnStake(false);
                    return
                }
                if (!dataClaim?.claim_pool) {
                    notiError('Unstake address invalid', 4)
                    setLoadingWithdraw(false);
                    setIsConfirmUnStake(false);
                    return
                }

                let withdrawContract: any;
                if (dataClaim && dataClaim?.claim_pool) {
                    withdrawContract = new web3.eth.Contract(
                        UnStakeAbi as unknown as AbiItem,
                        dataClaim?.claim_pool
                    );
                }


                const gasPrice = await web3.eth.getGasPrice();

                // console.log('withdrawContract', withdrawContract);
                // console.log('dataClaim?.id', dataClaim?.id);
                // console.log('amountClaim', amountClaim);
                // console.log('dataClaim?.claim_sign', dataClaim?.claim_sign);
                // console.log('dataClaim?.claim_pool', dataClaim?.claim_pool);

                const gasEstimate = await withdrawContract.methods
                    .claim(
                        dataClaim?.id,
                        amountClaim,
                        dataClaim?.claim_sign
                    )
                    .estimateGas({
                        from: address,
                        // value: valueStakeConverted,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });


                await withdrawContract.methods.claim(dataClaim?.id, amountClaim, dataClaim?.claim_sign)
                    .send({
                        from: address,
                        gas: gasEstimate,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    })
                    .then(async (resClaim: any) => {

                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resClaim?.transactionHash,
                                async function (err: any, rec: any) {
                                    if (rec) {
                                        clearInterval(interval);
                                        if (address && chainId) {
                                            getUserClaimInfo();
                                            checkUserHasRequested();
                                            getHistories();
                                        }

                                        setLoadingWithdraw(false);
                                        setIsConfirmUnStake(false);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingWithdraw(false);
                                        setIsConfirmUnStake(false);
                                        notiError('Error Staking', 3)
                                    }
                                }
                            )
                        }, 1000);
                    })
                    .catch((errStake: any) => {
                        console.log('errStake', errStake);
                        getUserClaimInfo();
                        checkUserHasRequested();
                        getHistories();
                        setIsModalFail(true);
                        notiError(errStake?.message || errStake, 3)
                        setLoadingWithdraw(false);
                        setIsConfirmUnStake(false);
                    })

            } else {
                getUserClaimInfo();
                checkUserHasRequested();
                getHistories();
                setIsModalFail(true);
                notiError('Unstake sign not found.', 3)
                setLoadingWithdraw(false);
                setIsConfirmUnStake(false);
            }

        } catch (error: any) {
            console.log('error', error?.message);
            getUserClaimInfo();
            checkUserHasRequested();
            getHistories();
            setIsModalFail(true);
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoadingWithdraw(false);
            setIsConfirmUnStake(false);
        }
    };

    const handleRetryClaimReward = async (item: any) => {
        try {
            if (!item) {
                notiError('Claim item invalid', 4);
                return
            };
            setLoadingRetryClaim(true);
            setLoadingKey(item?.id);

            let dataClaim = item;
            let amountClaim: any = dataClaim?.claim_amount;

            if (amountClaim <= 0) {
                notiError('Amount claim invalid', 4)
                setLoadingRetryClaim(false)
                return
            }
            if (!dataClaim?.claim_sign) {
                notiError('Claim sign invalid', 4)
                setLoadingRetryClaim(false)
                return
            }
            if (!dataClaim?.claim_pool) {
                notiError('Claim address invalid', 4)
                setLoadingRetryClaim(false)
                return
            }

            let withdrawContract: any;
            if (dataClaim && dataClaim?.claim_pool) {
                withdrawContract = new web3.eth.Contract(
                    UnStakeAbi as unknown as AbiItem,
                    dataClaim?.claim_pool
                );
            }


            const gasPrice = await web3.eth.getGasPrice();

            const gasEstimate = await withdrawContract.methods
                .claim(
                    dataClaim?.id,
                    amountClaim,
                    dataClaim?.claim_sign
                )
                .estimateGas({
                    from: address,
                    // value: valueStakeConverted,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                });

            await withdrawContract.methods.claim(dataClaim?.id, amountClaim, dataClaim?.claim_sign)
                .send({
                    from: address,
                    gas: gasEstimate,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                })
                .then(async (resClaim: any) => {

                    const interval = setInterval(function () {
                        web3.eth.getTransactionReceipt(
                            resClaim?.transactionHash,
                            async function (err: any, rec: any) {
                                if (rec) {

                                    clearInterval(interval);
                                    setLoadingRetryClaim(false);
                                    setIsModalSucees(true);
                                    if (address && chainId) {
                                        getUserClaimInfo();
                                        checkUserHasRequested();
                                        getHistories();
                                    }
                                }
                                if (err) {
                                    console.log("err", err);
                                    setLoadingRetryClaim(false)
                                    notiError('Error Claim', 3);
                                    setIsModalFail(true);
                                }
                            }
                        )
                    }, 1000);
                })
                .catch((errStake: any) => {
                    console.log('errStake', errStake)
                    notiError(errStake?.message || errStake, 3)
                    setLoadingRetryClaim(false);
                    setIsModalFail(true);
                })

        } catch (error: any) {
            console.log('error', error?.message)
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoadingRetryClaim(false);
            setIsModalFail(true);
        }
    };

    function getFormattedDate(date: any) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let hours = date.getHours().toString().padStart(2, '0');;
        let mins = date.getMinutes().toString().padStart(2, '0');;

        return year + '-' + month + '-' + day + ' ' + hours + ':' + mins;
    }

    const onCopy = () => {
        notiSuccess('Copied', 3)
    }

    const columns: any = [
        // {
        //     title: 'No.',
        //     dataIndex: '',
        //     render: (text:any, record:any, index:any) => {
        //         let idex = index+1;
        //         let syml = <span>{idex}</span>;

        //         return (
        //             <>{syml}</>
        //         )
        //     }
        // },
        {
            title: <><span className="text-gray-light">TYPE</span></>,
            dataIndex: '',
            render: (text: any, record: any) => {
                return (
                    <div className="text-white">
                        Withdraw Fund
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">STATUS</span></>,
            dataIndex: 'status',
            render: (text: any, record: any) => {
                return (
                    <div className="text-info">

                        <>
                            {!record.claimed && record?.claim_sign != "" && (
                                <div className="flex-left">
                                    <div className="dot danger"></div>
                                    <span>Unfinished</span>
                                </div>
                            )}
                            {record.claimed && record.status == "CLAIMED" && (
                                <div className="flex-left">
                                    <div className="dot success"></div>
                                    <span>Completed</span>
                                </div>
                            )}
                            {!record.claimed && record.status == "PROCESSING" && (
                                <div className="flex-left">
                                    <div className="dot warning"></div>
                                    <span>Processing</span>
                                </div>
                            )}
                        </>


                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">AMOUNT</span></>,
            dataIndex: 'amount',
            render: (text: any, record: any) => {
                return (
                    <div className="text-green">
                        {new Intl.NumberFormat("ja-JP").format(record?.amount)} HLUSD
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">HASH</span></>,
            dataIndex: 'hash',
            render: (text: any, record: any) => {
                let expoler: any = 'https://helascan.io/tx/';
                return (
                    <div className="text-info">
                        {record?.hash ?
                            <a href={expoler + record?.hash} target="_blank">{`${record?.hash.substring(0, 4)}...${record?.hash.substring(record?.hash.length - 4)}`}</a>
                            :
                            "---"}
                        &nbsp;
                        {record?.hash && (
                            <CopyToClipboard text={expoler + record?.hash} onCopy={onCopy}>
                                <Tooltip title="Copy hash">
                                    <img className="w-15" src="../images/copy-ref.svg" />
                                </Tooltip>
                            </CopyToClipboard>
                        )}

                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">DATE</span></>,
            dataIndex: 'created_at',
            render: (text: any, record: any) => {

                const createdAt = new Date(record?.created_at);

                let datTime = createdAt.getTime();
                let newDateTime = datTime - (8 * 60 * 60);
                let newDate = new Date(newDateTime);
                return (
                    <div className="text-gray-light">
                        {getFormattedDate(newDate)}
                    </div>
                )
            }
        },
        {
            title: <><span className="text-gray-light">ACTION</span></>,
            dataIndex: 'action',
            render: (text: any, record: any) => {
                return (
                    <div className="text-info">

                        <>
                            {!record.claimed && record?.claim_sign != "" && !isClaimBlocked && (
                                <Button className="btn-danger" type="primary" disabled={!address || loadingRetryClaim || chainId != record?.claim_chain_id} danger onClick={() => handleRetryClaimReward(record)}><span className="">Retry {loadingRetryClaim && loadingKey == record?.id && (<Spin />)}</span></Button>
                            )}

                        </>


                    </div>
                )
            }
        },

    ];

    const confirmUnStake = () => {
        setIsConfirmUnStake(true);
    }

    return (
        <>
            <div style={{ marginTop: 12 }} className="stake-block">
                <Row gutter={30}>

                    <Col xs={24} sm={24}>
                        <div className="">
                            <UserInfo claimInfo={claimInfo} />
                        </div>
                        <div className="text-center" style={{ marginTop: 24, marginBottom: 14 }}>
                            {configApy && configApy?.unstake_pool_chain != chainId && (
                                <Alert className="mt-15 mb-15" type="error" message={<>Wrong network, please switch to {chainName} </>}></Alert>
                            )}
                            {!userRequested ? (
                                <>

                                    {claimInfo != undefined && claimInfo?.claim_pool != "" && userClaimed == true ? (
                                        <>
                                            <Button size="large" className="btn-green" disabled htmlType="button">
                                                Unstaked <RightOutlined />
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            {!isUnstakeBlocked && (
                                                <Button size="large" className="btn-green" type="primary" htmlType="button" onClick={handleShowClaim}
                                                    disabled={isUnstakeBlocked || !address || !claimInfo || claimInfo?.hlusd_amount <= 0 || loadingWithdraw || configApy?.unstake_pool_chain != chainId}>
                                                    Unstake <RightOutlined /> {loadingWithdraw && <Spin indicator={antIcon} />}
                                                </Button>
                                            )}
                                        </>
                                    )}

                                </>
                            ) : (
                                <>
                                    {endTime && endTime > nowTime && (
                                        <div className="point-sum-info text-center" style={{ width: 'fit-content', margin: 'auto' }}>
                                            {CountdownReloadComponent(endTime)}
                                            <img src="../images/loading.svg" width={30} />
                                            <p className="mb-10 mt-0 font-16 text-white">Your request is now in progress.</p>
                                            <p className="mb-0 mt-10 text-gray-light">Fund can be claimed after 7 days.</p>
                                            <Button disabled className="btn-green mt-15" size="large">
                                                Withdraw Fund <RightOutlined />
                                            </Button>
                                        </div>
                                    )}
                                    {endTime && endTime < nowTime && !isWithdrawBlocked && (
                                        <>
                                            <div className="point-sum-info text-center" style={{ width: 'fit-content', margin: 'auto' }}>
                                                <p className={`icon-green`}><CheckOutlined /></p>
                                                <p className="mb-10 mt-0 font-16 text-white">7-day waiting period ends. You can now withdraw funds.</p>

                                                <Button disabled={!address || chainId != configApy?.unstake_pool_chain || Number(userRequested?.claim_amount) <= 0 || userRequested?.claim_sign != "" || loadingWithdraw} className="btn-green mt-15" size="large" onClick={confirmUnStake}>
                                                    {loadingWithdraw ? 'Withdrawing' : 'Withdraw Fund'}  {loadingWithdraw ? <Spin className="spin-white ml-5" /> : <RightOutlined />}
                                                </Button>

                                            </div>

                                        </>
                                    )}
                                    {/* Count down */}


                                </>
                            )}

                        </div>
                    </Col>
                </Row>
                <div className="powered mt-50">
                    <div className="powered-title">Withdraw History</div>
                    <div className="powered-list">
                        <div className="table-responsive">
                            {historys.length > 0 ? (
                                <>
                                    <Table className="table-cus" columns={columns} dataSource={historys} />
                                </>
                            ) : (
                                <>
                                    <Empty description={<span className="text-white">No history</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <Modal footer={false} className="modal-cus" title={<><span className="text-white weight-500 font-20">Unstake in pool</span></>} open={showUnstake} onOk={onOk} onCancel={onCancel}>
                <p className="text-blue border-bottom-green pb-10">Fund can be claimed after 7 days.</p>

                {!loadingWithdraw ? (
                    <>
                        <div className="mt-10">
                            <p className="text-white text-right font-18">Balance: <span className="weight-500">~{claimInfo && Number(claimInfo?.hlusd_amount) > 0 ? new Intl.NumberFormat("ja-JP").format(claimInfo?.hlusd_amount) : 0}</span> HLUSD</p>
                            <div className="unstaking-block">
                                <Input className="input-unstake" type="text" addonAfter={<><span>HLUSD</span></>} value={amountWithdraw} size="large" onChange={handleChangeAmount} placeholder="Input amount to be unstaked" />

                            </div>
                            {Number(amountWithdraw) > claimInfo?.hlusd_amount && (
                                <>
                                    <span className="text-danger">Amount invalid.</span>
                                </>
                            )}
                            <div className="flex-right mt-15">
                                <button className="btn-green-basic" onClick={() => sltAmountWithdraw(25)}>25%</button>
                                <button className="btn-green-basic" onClick={() => sltAmountWithdraw(50)}>50%</button>
                                <button className="btn-green-basic" onClick={() => sltAmountWithdraw(75)}>75%</button>
                                <button className="btn-green-basic" onClick={() => sltAmountWithdraw(100)}>100%</button>
                            </div>
                        </div>
                        <div className="text-center mt-15">
                            <>
                                {!isUnstakeBlocked && (
                                    <Button size="large" className="btn-dark full-width" disabled={!address || loadingWithdraw || Number(amountWithdraw) == 0 || Number(amountWithdraw) > claimInfo?.hlusd_amount} htmlType="button" onClick={() => handleRequestUnstake(amountWithdraw)}>
                                        {loadingWithdraw ? (
                                            <>
                                                Unstaking {loadingWithdraw && <Spin indicator={antIcon} />}
                                            </>
                                        ) : (
                                            <>
                                                Request Unstake
                                            </>
                                        )}

                                    </Button>
                                )}
                                <Button className="btn-green full-width mt-15" size="large" onClick={onCancel}>
                                    Cancel
                                </Button>
                            </>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="text-center">
                            <Spin size="large" />
                        </div>
                        <Button className="btn-dark full-width mt-15" size="large">
                            7 days - process
                        </Button>
                    </>
                )}


            </Modal>
            <Modal footer={false} className="modal-cus" title={<><span className="text-white weight-500 font-20"></span></>} open={isModalSucees} onOk={() => window.location.reload()} onCancel={() => window.location.reload()}>
                <div className="text-center">
                    <p className={`icon-green`}><CheckOutlined /></p>
                </div>
                <p className="text-center text-white font-20 mt-0">Congratulations!</p>
                <p className="text-center text-white font-18 mt-10">You have successfully unstaked.</p>
                <Button size="large" onClick={() => window.location.reload()} htmlType="button" className="btn-green full-width">OK</Button>

            </Modal>
            <Modal footer={false} className="modal-cus" title={<><span className="text-white weight-500 font-20"></span></>} open={isModalFail} onOk={() => window.location.reload()} onCancel={() => window.location.reload()}>
                <div className="text-center">
                    <p className={`icon-red`}><CheckOutlined /></p>
                </div>
                <p className="text-center text-white font-20 mt-0">Failed!</p>
                <p className="text-center text-white font-18 mt-10">Unstake unsuccessful.</p>
                <Button onClick={() => window.location.reload()} htmlType="button" type="primary" danger className="btn-danger full-width">Cancel</Button>

            </Modal>

            <Modal footer={false} className="modal-cus" onCancel={() => setIsConfirmUnStake(false)} onOk={() => setIsConfirmUnStake(false)} title={<></>} open={isConfirmUnStake}>
                <div className="text-center text-white font-24 mt-0 border-bottom-10 mb-15">Confirm UnStake!</div>
                <p className="text-center text-green font-18">Approve to transfer assets</p>
                <div className="text-center mb-15">
                    <div className="">
                        {!isUnstakeBlocked && (
                            <Button disabled={!address || chainId != configApy?.unstake_pool_chain || Number(userRequested?.claim_amount) <= 0 || userRequested?.claim_sign != "" || loadingWithdraw} className="btn-green mt-15" size="large" onClick={handleUnstake}>
                                {loadingWithdraw ? 'Approving' : 'Approve'}  {loadingWithdraw ? <Spin className="spin-white ml-5" /> : <RightOutlined />}
                            </Button>
                        )}
                    </div>

                </div>
            </Modal>
        </>
    );
};
export default Withdraw;
