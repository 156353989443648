
import { Col, message, Tabs, Layout, Row, Empty, Table, Button } from "antd";
import { Content, Header } from "antd/es/layout/layout";

import React, { useState, useRef, useEffect } from "react";


import { AbiItem } from "web3-utils";
import abiStake from '../../config/abi/abiStake.json'
import type { TabsProps } from 'antd';
import { InfoCircleOutlined } from "@ant-design/icons";
import HeaderApp from "../header-app";
import { BaseApi } from "../../config/api/BaseApi";
import Footer from "../../component/common/Footer";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import Web3 from "web3";
import { useParams } from "react-router-dom";
import Withdraw from "./withdraw-fund";
import CollectYield from "./collect-yield";
import { AdminApi } from "../../config/api/AdminApi";
import EBlacklistFeature from "../../constants/blacklist";


declare const window: Window &
  typeof globalThis & {
    trustwallet: any;
    ethereum: any;
    bitkeep: any;
    okexchain: any;
    safepalProvider: any;
  };

const ClaimPage = () => {

  const { address } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState()
  const chainId: any = selectedNetworkId;

  // const [amountTotalStaked, setAmountTotalStaked]: any = useState(0);
  const [countStaked, setCountStaked]: any = useState(0);
  const [pool, setPool]: any = useState(null);
  const [campaign, setCampaign]: any = useState(null);

  const [statistics, setStatistics]: any = useState();
  const [configApy, setConfigApy]: any = useState();
  const [isClaimBlocked, setIsClaimBlocked]: any = useState(true);
  const [isUnstakeBlocked, setIsUnstakeBlocked]: any = useState(true);
  const [isWithdrawBlocked, setIsWithdrawBlocked]: any = useState(true);

  let web3: any;
  const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");


  if (connectorId === "io.metamask" || connectorId === "bsc") {
    web3 = new Web3(window.ethereum);
  } else if (connectorId === "com.trustwallet.app") {
    web3 = new Web3(window.trustwallet);
  } else if (connectorId === "com.bitget.web3") {
    web3 = new Web3(window.bitkeep.ethereum);
  } else if (connectorId === "com.okex.wallet") {
    web3 = new Web3(window.okexchain);
  } else {
    web3 = new Web3(window.ethereum);
  }

  let stakeAddress = pool?.address;

  let stakeContract: any;
  if (stakeAddress) {
    stakeContract = new web3.eth.Contract(
      abiStake as unknown as AbiItem,
      stakeAddress
    );
  }

  const getStatistics = async () => {
    await BaseApi.getStatistics().then((res) => {
      if (res.status == 200 && res.data.data) {
        setStatistics(res.data.data);
      }
    }).catch();
  }
  const getConfigApy = async () => {
    await BaseApi.getConfigApy().then((res) => {
      if (res.status == 200 && res.data.data) {
        setConfigApy(res.data.data);
      }
    }).catch();
  }

  const checkBlacklist = async () => {
    try {
      if (!address) {
        return;
      }
      const response = await AdminApi.checkUserBlacklist(address, null);
      if (response.status === 200 && response.data) {
        const features = response.data.map((item: any) => item.feature);
        setIsUnstakeBlocked(features.includes(EBlacklistFeature.Unstake));
        setIsClaimBlocked(features.includes(EBlacklistFeature.ClaimReward));
        setIsWithdrawBlocked(features.includes(EBlacklistFeature.WithdrawUnstake));
      }
    } catch (error) {
      console.error('Error checking blacklist:', error);
    }
  };
  useEffect(() => {
    getConfigApy();
    getStatistics();

  }, [])

  useEffect(() => {
    checkBlacklist();
  }, [address, isUnstakeBlocked, isClaimBlocked, isWithdrawBlocked]);


  let hisTab = localStorage.getItem('keyTab');
  const [activeTab, setActiveTab] = useState<any>(hisTab ? hisTab : 'collect-yield');

  const items: TabsProps['items'] = [

    {
      key: 'collect-yield',
      label: <div className="flex-left"><img src="../images/staking.svg"></img> <span>Collect Yield</span></div>,
      children: <><CollectYield campaign={campaign} configApy={configApy} pool={pool} isClaimBlocked={isClaimBlocked} /></>,
    },
    {
      key: 'withdraw-fund',
      label: <div className="flex-left"><img src="../images/wallet-money.svg"></img> <span>Withdraw Fund</span></div>,
      children: <><Withdraw campaign={campaign} pool={pool} configApy={configApy} isUnstakeBlocked={isUnstakeBlocked} isWithdrawBlocked={isWithdrawBlocked} isClaimBlocked={isClaimBlocked} /></>,
    }
  ];

  const onChangeTabs = (key: string) => {
    localStorage.setItem('keyTab', key);
    setActiveTab(key);
    getStatistics();

    // window.location.reload();
  };


  return (
    <>
      <div className="app-page bg-main">
        <div className="page-content">
          <HeaderApp />
          <div className="container">
            <h2 className="text-center mb-15 head-title">Claim Pool</h2>
            <Tabs className="tabs-custome mt-20" style={{ marginTop: 30 }} centered activeKey={activeTab} items={items} onChange={onChangeTabs} />


          </div>

        </div>
        <Footer />
      </div>

    </>
  );
};
export default ClaimPage;
