import { Button, Modal, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDisconnect } from 'wagmi';
import { AdminApi } from '../../config/api/AdminApi';
import { notiError, notiSuccess } from '../../utils/notication';
import ContactForm from './contact-form';
import EBlacklistFeature from '../../constants/blacklist';

const ContactDetail = (prop: any) => {
    let jwtToken: any = localStorage.getItem('jwt');
    const { contact, address, updateContact, isBlacklistBlocked } = prop;
    const [showContactForm, setShowContactForm] = useState(false);
    const [viewImageUrl, setViewImageUrl] = useState<string | null>(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const { disconnect } = useDisconnect();
    const onViewImage = (imageUrl: string) => {
        setViewImageUrl(imageUrl);
    };
    const [contacts, setContacts] = useState<any[]>([]);
    const getContacts = async () => {
        try {
            const res = await AdminApi.getContactsByParentId(contact.id);
            if (res.status === 200 && res.data) {
                setContacts(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const addContact = async (contact: any) => {
        try {
            if (contact) {
                contact.owner = { address: address };
                setContacts([...contacts, contact]);
            }
        } catch (error) {
            console.error('Error adding contact:', error);
        }
    };
    useEffect(() => {
        if (contact) {
            getContacts();
        }
    }, [contact]);

    const isOwnerMessage = (contact: any) => {
        if (!contact) return false;
        return contact.replyToUser?.address != null ? contact.replyToUser?.address.toLocaleLowerCase() === address!.toLocaleLowerCase() : false;
    }

    const serverImage = (imageUrl: string, domain: string = ""): string | undefined => {
        if (!imageUrl) return undefined;
        if (imageUrl.endsWith('.zip')) return "../images/zip.png";
        if (imageUrl.endsWith('.rar')) return "../images/rar.png";

        const baseUrl = domain.includes("http") ? domain : '';
        return `${baseUrl}${imageUrl}`;
    };

    const handleCloseTicket = async () => {
        Modal.confirm({
            title: 'Are you sure you want to close this ticket?',
            onOk: async () => {
                await AdminApi.closeTicket(contact.id)
                    .then((res) => {
                        if (res.status === 200 && res.data) {
                            notiSuccess('Your ticket closed.', 5);
                            contact.status = 2;
                            updateContact(contact);
                            setIsOpenModal(false);
                        } else {
                            notiError("Failed to close your ticket. Please try again later.", 5);
                        }
                    }).catch((error) => {
                        if (error.response && error.response.status === 401 && jwtToken) {
                            notiError('Your session has expired. Please log in again.', 5);
                            disconnect();
                            localStorage.removeItem('jwt');
                        } else {
                            notiError('Failed to close your ticket. Please try again later.', 5);
                        }
                    });
            },
            className: 'modal-cus custom-confirm-modal',
            okButtonProps: { className: 'custom-ok-button btn-green' },
            cancelButtonProps: { className: 'custom-cancel-button btn-dark' }
        });
    };

    return (
        <>
            <div className="contact-form-detail-container">
                {contacts && contacts.map((contactItem: any, i) => (
                    <div className="contact-form-detail" key={i}>
                        <div className="right-panel">
                            <div className="user-info">
                                {isOwnerMessage(contactItem) && <div className="operator">StableHodl</div>}
                                {!isOwnerMessage(contactItem) && <div className="owner">You</div>}
                                <Tooltip title={new Date(contactItem.createdAt).toLocaleString()}>
                                    <div className="created-at">{new Date(contactItem.createdAt).toLocaleTimeString()}</div>
                                </Tooltip>
                            </div>
                            {contactItem.imageUrl && (
                                <>
                                    <img
                                        className="viewer m-t-10"
                                        src={serverImage(contactItem.imageUrl, process.env.REACT_APP_ADMIN_API_URL)}
                                        alt="Click to view"
                                        width="55px"
                                        height="55px"
                                        onClick={() => {

                                            if (contactItem.imageUrl.endsWith('.zip') || contactItem.imageUrl.endsWith('.rar')) {
                                                window.open(process.env.REACT_APP_ADMIN_API_URL + contactItem.imageUrl, '_blank');
                                            } else {
                                                onViewImage(contactItem.imageUrl);
                                                setIsOpenModal(true);
                                            }
                                        }}
                                    />
                                    <Modal className="modal-cus contact-modal preview-modal" title={<><span className="text-white weight-500 font-20">Preview image</span></>}
                                        visible={isOpenModal}
                                        footer={null}
                                        onCancel={() => setIsOpenModal(false)}
                                    >
                                        <img
                                            src={viewImageUrl ? serverImage(viewImageUrl, process.env.REACT_APP_ADMIN_API_URL) : ''}
                                            alt="Full view"
                                            style={{ width: '100%' }}
                                        />
                                    </Modal>
                                </>
                            )}
                            <div className="content">{contactItem.content}</div>
                            {isOwnerMessage(contactItem) && contact.status != 2 && (
                                <>
                                    <div className='button-group'>
                                        <Button
                                            color="primary"
                                            className="btn-green btn-reply"
                                            onClick={() => setShowContactForm(!showContactForm)}
                                            disabled={isBlacklistBlocked === true}
                                        >
                                            <span className="p-l-5">{!showContactForm ? "Reply" : "Cancel"}</span>
                                        </Button>
                                        &nbsp;
                                        {i === contacts.length - 1 && (<Button
                                            color="primary"
                                            className="btn-close btn-dark ml-5"
                                            onClick={() => handleCloseTicket()}
                                            disabled={isBlacklistBlocked === true}
                                        >
                                            <span className="p-l-5">Close Ticket</span>
                                        </Button>
                                        )}
                                    </div>
                                    {showContactForm && <ContactForm address={address} parentId={contactItem.parentId} addContact={(contactItem: any) => addContact(contactItem)} isBlacklisted={isBlacklistBlocked} />}
                                </>
                            )}
                            {!isOwnerMessage(contactItem) && contact.status != 2 && (
                                <>
                                    {i === contacts.length - 1 && !isBlacklistBlocked && (
                                        <div className='button-group'>
                                            <Button
                                                color="primary"
                                                className="btn-close btn-dark ml-5"
                                                onClick={() => handleCloseTicket()}
                                                disabled={isBlacklistBlocked === true}
                                            >
                                                <span className="p-l-5">Close Ticket</span>
                                            </Button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {contacts.length == 0 && (
                <div>
                    {contact.status !== 2 && (
                        <Button
                            color="primary"
                            className="btn-close btn-dark mb-10"
                            onClick={() => handleCloseTicket()}
                            disabled={isBlacklistBlocked}
                        >
                            <span className="p-l-5">Close Ticket</span>
                        </Button>
                    )}
                </div>
            )}
        </>
    );

};

export default ContactDetail;