import { Route, Routes } from "react-router-dom";

import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { arbitrum, bsc, mainnet, polygon } from 'wagmi/chains';
import ClaimPage from './pages/claim';
import Homepage from './pages/home';
import LaunchingSoon from './pages/launching-soon';
import Leaderboard from './pages/leaderboard';
import Profile from './pages/profile';
import StakingPage from './pages/staking';
import StakingList from './pages/staking-list';
import Trade from './pages/trade';
import FAQs from './pages/faqs';

const DEFAULT_WALLETS = [
  'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
  '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
  '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
  '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
  '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
  'c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a',
  '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150',
  '15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f',
  '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
  '225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f',
  'f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d',
  'c286eebc742a537cd1d6818363e9dc53b21759a1e8e5d9b263d0c03ec7703576',
  '541d5dcd4ede02f3afaf75bf8e3e4c4f1fb09edb5fa6c4377ebf31c2785d9adf',
  '18450873727504ae9315a084fa7624b5297d2fe5880f0982979c17345a138277'
]

const queryClient = new QueryClient()
const projectId = '90442772461be13996b4d4766e81fd8f'
// 2. Create wagmiConfig
const metadata = {
  name: 'StableHodl',
  description: 'Be an early contributor to HeLa, earn real yield and get boosted points',
  url: 'https://stablehodl.com', // origin must match your domain & subdomain
  icons: ['https://stablehodl.com/images/stable-hodl.svg']
}

const helaTestnet: any = {
  id: 666888,
  name: 'HeLa Official Testnet',
  nativeCurrency: { name: 'HLUSD', symbol: 'HLUSD', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://testnet-rpc.helachain.com'] },
  },
  blockExplorers: {
    default: { name: 'HeLascan', url: 'https://testnet.helascan.io/' },
  },
 testnet: true
}
const helaMainet: any = {
  id: 8668,
  name: 'HeLa Official Runtime Mainnet',
  nativeCurrency: { name: 'HLUSD', symbol: 'HLUSD', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://mainnet-rpc.helachain.com'] },
  },
  blockExplorers: {
    default: { name: 'HeLascan', url: 'https://helascan.io/' },
  },
 testnet: false
}

const chains = [bsc, mainnet, polygon, helaTestnet, helaMainet, arbitrum] as const

const config = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: false, // Optional - true by default
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  excludeWalletIds: DEFAULT_WALLETS,
  allWallets: 'HIDE'
})

function App() {
    
    return (
        <div className="App">
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
              <Routes>
                  <Route path={`/`} element={<Homepage />} />
                  <Route path={`/stakes`} element={<StakingList />} />
                  <Route path={`/stake/:seo`} element={<StakingPage />} />
                  
                  <Route path={`/launching-soon`} element={<LaunchingSoon />} />
                  <Route path={`/leaderboard`} element={<Leaderboard />} />
                  <Route path={`/profile`} element={<Profile />} />
                  <Route path={`/claim`} element={<ClaimPage />} />
                  <Route path={`/trade`} element={<Trade />} />
                  <Route path={`/faqs`} element={<FAQs />} />
              </Routes>
            </QueryClientProvider>    
          </WagmiProvider>
        </div>
    );
}

export default App;
