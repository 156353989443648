import { instanceAdmin } from '../../apiBase/instanceAdmin';
var authToken: any = localStorage.getItem('authToken');
let jwtToken: any = localStorage.getItem('jwt')

export const AdminApi = {
	sendContact(contactInfo: any) {
		return instanceAdmin.post<any>(`admin-api/contact/send`, contactInfo, {
			headers: {
				Authorization: `Bearer ${jwtToken}`
			}
		});
	},

	getContacts(count: boolean = false, page: number = -1, pageSize: number = -1) {
		return instanceAdmin.put<any>(`admin-api/contact/list`, {
			"view": "default",
			"page": page,
			"pageSize": pageSize,
			"count": count,
			"sort": "created_at desc"
		}, {
			headers: {
				Authorization: `Bearer ${jwtToken}`
			}
		});
	},

	getContactsByParentId(parentId: number) {
		return instanceAdmin.put<any>(`admin-api/contact/list`, {
			"view": "sub",
			"parentId": parentId,
			"sort": "created_at asc"
		},
			{
				headers: {
					Authorization: `Bearer ${jwtToken}`
				}
			}
		);
	},

	signIn(address: string, message: string, signature: string, issuedAt: number) {
		return instanceAdmin.post<any>(`/admin-api/auth/end-user/login`, {
			address,
			message,
			signature,
			issuedAt
		}, {});
	},

	uploadImage(imageData: FormData) {
		return instanceAdmin.post<any>(`/admin-api/contact/upload-image`, imageData, {
			headers: {
				Authorization: `Bearer ${jwtToken}`,
				'Content-Type': 'multipart/form-data'
			},
			maxContentLength: 100 * 1024 * 1024, // 100 MB
			maxBodyLength: 100 * 1024 * 1024 // 100 MB
		});
	},

	removeImage(imageUrl: string) {
		return instanceAdmin.post<any>(`/admin-api/contact/remove-image`, { imageUrl: imageUrl }, {
			headers: {
				Authorization: `Bearer ${jwtToken}`
			}
		});
	},

	closeTicket(ticketId: number) {
		return instanceAdmin.put<any>(`admin-api/contact/close`, { "ticketId": ticketId }, {
			headers: {
				Authorization: `Bearer ${jwtToken}`
			}
		});
	},

	checkUserBlacklist(address: string, feature: any = null) {
		return instanceAdmin.get<any>(`/admin-api/blacklist/status`, {
			params: {
				address: address.toLocaleLowerCase(),
				feature: feature
			}
		});
	},

};
